import React, { Component } from 'react'

import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import Contact from '../containers/ContactView.js';
import About from '../scenes/About.js';
import NotFound from '../scenes/NotFound.js'
import Home from '../scenes/Home.js'
import Login from '../containers/LoginView.js'
import SignUp from '../scenes/Join.js'
import Diets from '../containers/DietsView.js'
import Diet from '../containers/DietView.js'
import ResetPassword from '../scenes/ResetPassword.js'
import ChangePassword from '../scenes/ChangePassword.js'
import LayoutTool from '../containers/LayoutToolView.js'
import PlantingsTable from '../containers/PlantingsTableView.js'

export default class Routes extends Component {

  render() {
    return (
      <main>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/about' component={About}/>
          <Route path='/contact' component={Contact}/>
          <Route path='/login' component={Login}/>
          <Route path='/join' component={SignUp} />
          <Route path='/reset_password' component={ResetPassword} />
          <Route path='/change_password' component={ChangePassword} />
          <Route path='/diets/:dietId/layout' component={LayoutTool}/>
          <Route path='/diets/:dietId/table' component={PlantingsTable} />
          <Route path='/diets/:dietId' component={Diet}/>
          <Route path='/diets' component={Diets}/>

          <Route component={NotFound} />
        </Switch>
      </main>
    )
  }
}
