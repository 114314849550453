import React, { Component } from 'react'
import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import HorizontalTimeline from 'react-horizontal-timeline'
import moment from 'moment'

let timelineDates = []
let timelineDatesWithIndex = {} // internal only
const start = moment().subtract(1, 'years');
const end = moment().add(1, 'years');
let dateIdx = 0
let todayIdx = 0
for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'weeks')) {
  let formattedDate = m.format('YYYY-MM-DD')
  timelineDates.push(formattedDate);
  timelineDatesWithIndex[dateIdx] = formattedDate
  let startDate = moment() //.subtract(7, 'days')
  let endDate = moment().add(1, 'weeks')
  
  // (formattedDate  == moment().format('YYYY-MM-DD')) 
  if ((m == startDate || m == endDate) || m.isBetween(startDate, endDate)){
    
    // FIXME: this library needs an index of the date you want to select
    // The issue is that we are paginating by week and need to find that exact week which isn't easy.
    todayIdx = dateIdx -1 // choose previous week
  }
  dateIdx++
}


export default class GardenTimeView extends Component {
  state = { selectedIndex: null  };
  render() {

    return (
      <div style={{ width: '60%', height: '100px', margin: '0 auto' }}>
        <HorizontalTimeline
          indexClick={(index) => {
            const selectedDate = timelineDatesWithIndex[index]

            this.props.handleChange(selectedDate)

            this.setState({
              selectedIndex: index,
            });
          }}
          values={ timelineDates }
          minEventPadding={50}
          maxEventPadding={50}
          linePadding={60}
          labelWidth={70}
          index={this.state.selectedIndex || todayIdx}
          slidingMotion={{ stiffness: 500, damping: 0 }}
        />
      </div>
    )
  }
}