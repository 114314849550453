import React, { Component } from 'react'
import displayErrorMessages from '../utilities/DisplayErrorMessages'
import AlertContainer from 'react-alert';

export default class AddPerson extends Component {
  alertOptions = {
    offset: 14,
    position: 'top right',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  constructor(props) {
    super(props)

    // TODO: Add state props types!
    this.state = {
      name: '',
      age: '',
      weight_kg: '',
      height: '',
      exercise_level_id: '',
      sex_id: '',
      lactating: '',

      genderOptions: [],
      exerciseOptions: [],
      pregnantOptions: []
    }

  }

  handleLactatingChange = event => {
    this.setState({lactating: event.target.value})
  }

  handleNameChange = event => {
    this.setState({name: event.target.value});
  }

  handleGenderChange = event => {
    this.setState({sex_id: event.target.value});
  }

  handleExerciseLevelChange = event => {
    this.setState({exercise_level_id: event.target.value});
  }

  handleWeightChange = event => {
    this.setState({weight_kg: event.target.value});
  }

  handleHeightChange = event => {
    this.setState({height: event.target.value});
  }

  handleAgeChange = event => {
    this.setState({age: event.target.value})
  }

  updatePerson(personId) {
    fetch(`/api/people/${this.props.personId}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
      },
      body: JSON.stringify({
        name: this.state.name,
        age: this.state.age,
        weight_kg: this.state.weight_kg,
        height: this.state.height,
        sex_id: this.state.sex_id,
        exercise_level_id: this.state.exercise_level_id,
        diet_id: this.props.diet.id,
      })
    })
    .then(resp => {
      if (resp.status === 200) {
        
      }
      return resp.json()
    })
    .then((response) => {
      this.props.updatePeople(response);
      this.props.showSuccessMsg('Person Updated');
      this.props.formSubmitted();
    })
    .catch(error => {
      
    })
  }

  clearForm() {
    this.setState({
      name: '',
      age: '',
      weight_kg: '',
      height: '',
      exercise_level_id: '',
      sex_id: '',
      lactating: '',
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    if (!this.props.newPerson) {
      return this.updatePerson()
    }

    let name = this.state.name;
    let sex_id = this.state.sex_id;
    let exercise_level_id = this.state.exercise_level_id;
    let weight_kg = this.state.weight_kg;
    let height = this.state.height;
    let age = this.state.age;

    fetch('/api/people', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
      },
      body: JSON.stringify({
        person: {
          name: name,
          age: age,
          weight_kg: weight_kg,
          height: height,
          height_in: "inch",
          sex_id: sex_id,
          exercise_level_id: exercise_level_id,
          diet_id: this.props.diet.id,
          pregnancy_stage_id: 1

        }

      })
    })
    .then((resp) => {
      if (resp.status == 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login');
        return
      }
      if (resp.status === 200) {
        this.props.showSuccessMsg('Person Created');
        this.clearForm()
        this.props.formSubmitted();
      }

      return resp.json()
    })
    .then((response) => {
      if(response.errors) {
        Object.entries(response.errors).forEach(error => {
          this.msg.show(`${error[0]}: ${error[1]}`, {
            position: 'top right',
            time: 5000,
            type: 'error',
            transition: 'fade'
          })
        })
        return
      }
      this.props.updatePeople(response)
    })
    .catch((error) => {
      this.msg.show(error, {
        position: 'top right',
        time: 5000,
        type: 'error',
        transition: 'fade'
      })
    });
  }

  componentDidMount() {
    // Load form with selections
    fetch(`/api/people/new`, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      this.setState({
        genderOptions: json.genders,
        exerciseOptions: json.exercise_levels
      })
    })
    .catch(error => console.log(error))

    // If editing, pre-fill form
    if (this.props.personId) {
      fetch(`/api/people/${this.props.personId}`, {
        headers: {
          'Authorization': `Bearer ${this.props.authenticated.jwt}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      })
      .then(resp => resp.json())
      .then(json => {
        let person = json.person

        this.setState({
          name: person.name,
          age: person.age,
          weight_kg: person.weight_kg,
          height: person.height,
          exercise_level_id: person.exercise_level_id,
          sex_id: person.sex_id,
          lactating: person.lactating,
          pregnancy_stage_id: 1

        });
      })
      .catch(error => {

      })
    }
  }

  onDeletePerson(e) {
    // e.preventDefault()
  
    fetch(`/api/people/${this.props.personId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.props.authenticated.jwt}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      })
      .then(resp => {
        if (resp.status === 204) {
          this.clearForm()
          this.props.formSubmitted();

          this.props.destroyPerson(this.props.personId)
          this.props.showSuccessMsg('Person Removed');
        }
        
      })
      .catch(error => {
        
      })
  }

  render() {
    const massLabel = this.props.diets[this.props.diet.id].area_option_id == 2 ?  "lbs." : "kg."
    const heightLabel = this.props.diets[this.props.diet.id].area_option_id == 2 ?  "in." : "cm."
    return (
      <form className="measure center" onSubmit={this.handleSubmit}>
        <AlertContainer ref={a => this.msg = a} />

        <fieldset id="new_diet" className="ba b--transparent ph0 mh0">
          <legend className="f4 fw6 ph0 mh0">{this.props.formLegend}</legend>
          <div className="mt3">
            <label className="db fw6 lh-copy f6">Person Name</label>
            <input placeholder="John Smith" onChange={this.handleNameChange} value={this.state.name} className="pa2 input-reset ba bg-transparent w-80"  />
          </div>
          <div className="mt3">
            <label className="db fw6 lh-copy f6">Weight ({massLabel})</label>
            <input type="number" onChange={this.handleWeightChange} min="0" value={this.state.weight_kg} className="pa2 input-reset ba bg-transparent w-20" />
          </div>
          <div className="mt3">
            <label className="db fw6 lh-copy f6">Height ({heightLabel})</label>
            <input type="number" onChange={this.handleHeightChange} min="0" value={this.state.height} className="pa2 input-reset ba bg-transparent w-20" />
          </div>
          <div className="mt3">
            <label className="db fw6 lh-copy f6">Age</label>
            <input type="number" onChange={this.handleAgeChange} min="1" value={this.state.age} className="pa2 input-reset ba bg-transparent w-20" />
          </div>
          <div className="mv3">
            <label className="db fw6 lh-copy f6">Sex</label>
            <select onChange={this.handleGenderChange} value={this.state.sex_id} ref="userInput" className="pa2 ">
              <option value="" ></option>
              {
                this.state.genderOptions.map(function(opt) {
                  return <option key={opt.id}
                    value={opt.id}>{opt.name}</option>;
                })
              }
            </select>
          </div>
          <div className="mv3">
            <label className="db fw6 lh-copy f6">Exercise Level</label>
              <select onChange={this.handleExerciseLevelChange} value={this.state.exercise_level_id} ref="userInput" className="pa2 w-80">
                <option value="" ></option>
                {
                  this.state.exerciseOptions.map(function(opt) {
                    return <option key={opt.id}
                      value={opt.id}>{opt.description}</option>;
                  })
                }
              </select>
          </div>
        </fieldset>
        <div className="">
          <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Save Person" />
          { !this.props.newPerson ?
            <button onClick={ () => this.onDeletePerson() } className='ml2 pointer f6 link dim br1 ba ph3 pv2 mb2 dib dark-red'>Delete</button> :
            null
          }
          
        </div>

      </form>
    )
  }

}
