import React, { Component } from 'react'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import '../styles/react-table-overrides.css'
import DietNavBarView from '../containers/DietNavBarView'
import { Button } from "@blueprintjs/core";
import GrowingAreaForm from '../containers/GrowingAreaFormView.js'
import PlantCropForm from '../containers/PlantCropFormView'
import ReactModal from 'react-modal'
import ModalStyles from '../styles/TallModal.js'
import GardenTimelineView from './GardenTimelineView.js'
import _ from 'underscore'

export default class PlantingsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      growingAreas: [],
      showCropForm: false,
      showGrowingAreaForm: false,
      plantingsToRender: []
    }
  }

  handleCloseCropForm = () => {
    this.setState({ showCropForm: false });
  }

  handleOpenCropForm = () => {
    this.setState({ showCropForm: true })
  }

  isLoading = () => {
    return this.state.loading || this.props.loading
  }

  componentDidMount() {
    fetch(`/api/diets/${this.props.diet.id}/growing_areas`, {
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      let plantings = {}
      _.each(json.growing_areas, (growingArea) => {
        // Store ID of each growing area of each in local state
        this.props.updateGrowingAreaId(growingArea.id)

        // Store ID of each planting in local state
        _.each(growingArea.plantings, (planting) => {
          this.props.updateGrowingAreaPlanting(growingArea.id, planting.id)
          plantings[planting.id] = planting
        })
      })

      // this.props.setGrowingAreas(json.growing_areas)
      // this.setState({ growingAreaOptions: json.growing_areas })
      this.props.setPlantings(plantings)
    })
    .catch(error => console.log(error))
  }


  handleAddGrowingArea() {

  }

  render() {
    const growingAreas = this.props.growingAreas
    let _plantings = []
    Object.entries(this.props.plantings).forEach(planting => {
      // Object.entries(this.props.plantings || {}).forEach(planting => {
        _plantings.push({
          crop_name: planting[1].crop_name,
          usage: planting[1].usage_word,
          length: planting[1].area_planted_h.toFixed(2),
          width: planting[1].area_planted_w.toFixed(2),
          area_planted: planting[1].area_planted,
          name: planting[1].growing_area_name
        })
      // })
    })


    const columns = [
      {
        Header: () => ( <div className='tl b'>Area Name</div>),
        accessor: 'name',
        width: 200
      },
      {
        Header: () => ( <div className='tl b'>Crop</div>),
        accessor: 'crop_name',
        width: 200
      },
      {
        Header: () => ( <div className='tl b'>Length</div>),
        accessor: 'length',
        width: 200
      },
      {
        Header: () => ( <div className='tl b'>Width</div>),
        accessor: 'width',
        width: 200
      },
      
      {
        Header: () => ( <div className='tl b'>Usage</div>),
        accessor: 'usage',
        width: 200
      },
      // {
      //   Header: () => ( <div className='tl b'>Status</div>),
      //   accessor: 'status',
      //   width: 200
      // },
    ]
    return (
      <div>
        <DietNavBarView dietChanged={() => {}}/>
        <div className="pa3">
          <div className="mb3">
          { Object.entries(this.props.growingAreas).length == 0 ?
            <div className="bp3-callout bp3-intent-primary mb2"><h4 className="bp3-heading">Important!</h4>Nothing has been planted yet, please visit the Garden View to plant. </div> :
            <Button icon="plus" onClick={this.handleOpenCropForm}>Add Crop</Button>
          }
            {/* <Button icon="plus" className="mr2" onClick={this.handleAddGrowingArea}>Add Growing Area</Button> */}
            
          </div>
          <ReactTable
            loading={this.isLoading()}
            loadingText='Loading...'
            noDataText='Nothing has been planted in the Garden View'
            data={_plantings}
            columns={columns}
            defaultPageSize={10}
            className="large-column -striped -highlight"
          
          />

          <div style={{
                    position: 'fixed',
                    bottom: '0',
                    width:'100%',
                    height: 140,
                    backgroundColor: '#FAFAFA',
                    padding: 20,
                    borderTop: '1px solid #CCCCCC',
                    boxShadow: '9px 5px 21px black'
                    }}>
                    <small>CURRENT WEEK DISPLAYED:</small>

                    <GardenTimelineView handleChange={(dateSelection) => { this.forceUpdate()}}/>
                  </div>
        </div>

        <ReactModal
           isOpen={this.state.showCropForm}
           contentLabel='Add Crop'
           style={ModalStyles}
        >
          <button className="f7 link dim br-pill ba ph3 pv2 mb2 dib" style={{ position:'absolute', right: '20px' }} onClick={this.handleCloseCropForm}>Close</button>

          { this.state.showCropForm ?
            <PlantCropForm
              growingAreaId=""
              harvestId=""
              disableSelections={false}
              onRequestClose={ this.handleCloseCropForm }
              onFormSubmit={ this.handleCloseCropForm } />
          : null }
        </ReactModal>
      </div>
    )
  }
}
