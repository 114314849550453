
export default function cropIds(state = {}, action) {
  let newObj = {}

  switch (action.type) {
    case 'CROPS.CHANGE':
      return Object.assign({}, state, { id: action.id });
    case 'CROPS.UPDATE':
      newObj[action.data.id] = action.data
      return Object.assign({}, state, newObj);
    case 'CROPS.SET':
      action.data.forEach((crop) => {
        newObj[crop.id] = crop
      })
      return Object.assign({}, state, newObj) 
    case 'CROPS.DELETE':
      Object.assign(state, newObj)
      delete newObj[action.data]
      return newObj
    default:
      return state;
  }
}
