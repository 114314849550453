import React, { Component } from 'react';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = { contentHtml: "" };
  }

  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <h1>404.. This page is not found!</h1>
      </section>
    );
  }
}

export default NotFound;
