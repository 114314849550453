import _ from 'underscore'

// Handles ajax response form messaging
// Loops through each form error and pushes message redux
//
// props - redux props
// response - form response obj.
export default function displayErrorMessages(props, response) {
  if (response.error && response.error == 'Failed to Login') {
    props.showErrorMsg('Please refresh the page and login.');

    return;
  }
  if (response.errors) {
    _.each(response.errors, (errors, field_name) => {
      props.showErrorMsg(`${field_name}: ${errors[0]}`)
    });
  }
}
