// Taken from: https://medium.com/@notrab/getting-started-with-create-react-app-redux-react-router-redux-thunk-d6a19259f71f
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './modules'

export const history = createHistory()

const initialState = {
  authenticated: { jwt: localStorage.getItem('jwtToken') },
  diet: { id:  document.location.pathname.match('/diets/') ? Number(document.location.pathname.split('/')[2]) : null }, // FIXME: this is hacky and is not always correct
  diets: {},
  growingAreas: {},
  plantings: {},
  growingAreaIds: {},
  crops: {},
  cropIds: [],
  people: [],
  nutrientLimits: {},
}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
)

export default store
