import React, { Component } from 'react'
import DietSelector from '../containers/DietSelectorView.js'
import GardenViewDraggable from '../containers/GardenViewDraggableView'
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Crop from '../components/Crop'
import _ from 'underscore'
import { Link } from 'react-router-dom'
import { Button } from "@blueprintjs/core";

export default class LayoutTool extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nutrientColumns: []
    }
  }

  componentDidMount() {
    fetch('/api/nutrients/columns', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
      }})
      .then((resp) => resp.json())
      .then((json) => {
        this.setState({ nutrientColumns: json.columns })
      })
  }

  render() {
    const dietPath = `/diets/${this.props.diet.id}`;
    const plantingsTableLink = `/diets/${this.props.diet.id}/table`
    return(
        <span>
          <section className="pa3 pa3 hp-sub-nav">
            <DietSelector dietVal={this.props.diet.id} dietChanged={()=>{}} />
            <span className="pa3">
              <Link to={dietPath} className="no-underline">
                <Button>Diet View</Button>
              </Link>
              <Link to={plantingsTableLink} title='Plantings Table'>
                <Button>Table View</Button>
              </Link>
            </span>
          </section>
          <div className='fl w-100'>
            <DragDropContextProvider backend={HTML5Backend}>
              <GardenViewDraggable/>
            </DragDropContextProvider>
          </div>
       </span>
    )
  }
}
