export function updateGrowingAreaId(growingAreaId) {
  return { type: 'UPDATE_GROWING_AREA_ID', data: growingAreaId }
};

export function setGrowingAreaIds(growingAreaIds) {
  return { type: 'SET_GROWING_AREA_IDS', data: growingAreaIds}
}

export function clearGrowingAreaIds(){
  return { type: 'CLEAR_GROWING_AREA_IDS', data: {} }
}

export function updateGrowingAreaPlanting(growingAreaId, plantingId){
  let data = {
    plantingId: plantingId,
    growingAreaId: growingAreaId
  }
  return { type: 'UPDATE_GROWING_AREA_PLANTING', data: data }
}

export function destroyGrowingAreaId(growingAreaId) {
  return {
    type: 'GROWING_AREA_IDS.DELETE',
    data: growingAreaId
  }
}