import { loggedIn, loggedOut } from '../actions/Authenticate'
import Crop from '../components/Crop'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return { }
}

function mapDispatchToProps(dispatch) {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crop);
