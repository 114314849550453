import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class LoginButton extends Component {
  render() {
    return (
      <Link className="f6 link dim br2 ba ph3 pv2 mb2 dib white" to='/login' title='Sign In'>Sign in</Link>
    )
  }
}
