import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';

import LoginButton from '../components/LoginButton.js'
import LogoutButton from '../containers/LogoutButtonView.js'
import JoinButton from '../components/JoinButton.js'
import Main from '../components/Routes.js'

class Nav extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <header className='bg-hp-green w-100 pt2 ph3-m ph3-l'>
        <nav className="f6">
          <Link className='link dim white dib mr3 tracked' to='/' style={{marginRight: '100px'}}><strong>HARVEST PLANNER</strong></Link>
          <Link className='link dim white dib mr3' to='/about' title="About">About</Link>
          <Link className='link dim white dib mr3' to='/contact' title="Contact">Contact</Link>
          { this.props.authenticated.jwt ? <Link className='link dim white dib mr3' to='/diets' title="Diets">Diets</Link> : null }
          { !this.props.authenticated.jwt ? <LoginButton /> : null }
          { this.props.authenticated.jwt ? <LogoutButton /> : null }
          { this.props.authenticated.jwt ? null : <JoinButton /> }
        </nav>
      </header>
    )
  }
}


export default Nav;
