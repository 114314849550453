import { loggedIn, loggedOut } from '../actions/Authenticate'
import LogoutButton from '../components/LogoutButton'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return { authenticated: state.authenticated }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    }
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(
  LogoutButton
)
