import growingAreaHOC from '../components/GrowingArea'
import { connect } from 'react-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { loggedIn, loggedOut } from '../actions/Authenticate'
import { push } from 'react-router-redux'
import { updateGrowingArea, setGrowingAreas, destroyGrowingArea } from '../actions/GrowingArea'
import { destroyGrowingAreaId } from '../actions/GrowingAreaId'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    diets: state.diets,
    growingAreas: state.growingAreas,
    plantings: state.plantings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updateGrowingArea: (growingArea) => {
      dispatch(updateGrowingArea(growingArea));
    },

    destroyGrowingArea: growingAreaId => {
      dispatch(destroyGrowingArea(growingAreaId))
    },

    destroyGrowingAreaId: growingAreaId => {
      dispatch(destroyGrowingAreaId(growingAreaId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(growingAreaHOC)
