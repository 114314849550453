import { loggedIn, loggedOut } from '../actions/Authenticate'
import { connect } from 'react-redux'
import CropsTable from '../components/CropsTable'
import { showAlert, showSuccess } from '../actions/Alert'
import { destroyCrop, updateCrops } from '../actions/Crops'
import { destroyCropId } from '../actions/CropIds'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    diets: state.diets,
    diet: state.diet,
    crops: state.crops,
    cropIds: state.cropIds,
    people: state.people,
  }
}

function mapDispatchToProps(dispatch) {
  return {

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    destroyHarvest: (id) => {
      dispatch(destroyCrop(id))
    },

    destroyCropId: (id) => {
      dispatch(destroyCropId(id))
    },

    updateCrop: (crop) => {
      dispatch(updateCrops(crop))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CropsTable);
