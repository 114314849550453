import DietNavBar from '../components/DietNavBar'
import { connect } from 'react-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { destroyDiet } from '../actions/Diets'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    diets: state.diets,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    destroyDiet: dietId => {
      dispatch(destroyDiet(dietId))
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    redirectTo: (path) => {
      dispatch(push(path))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DietNavBar);
