import AlertContainer from 'react-alert'
import { useAlert } from 'react-alert'

import AlertOptions from '../styles/AlertOptions'
import React, { Component } from 'react'

export default class AlertMsg extends Component {
  render() {
    let props = this.props

    return(

      <AlertContainer ref={ (alert) => {
        if (props.visible && alert) {
          switch(props.type) {
            case 'SUCCESS':
              alert.show(props.message, {type: 'success'})
              break;
            case 'ERROR':
              alert.show(props.message, {type: 'error'})
              break;
          }
        }

      }} {...AlertOptions} />

    )
  }
};

