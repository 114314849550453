export default function people(state = {}, action) {
  let newObj = {}

  switch(action.type) {
    case 'PEOPLE.UPDATE':
      newObj[action.data.id] = action.data
      return Object.assign({}, state, newObj);
    case 'PEOPLE.SET':
      action.data.forEach((person) => {
        newObj[person.id] = person
      })
      return newObj
    case 'PEOPLE.DESTROY':
      let newState = {}
      Object.assign(newState, state)
      delete newState[action.data]
      
      return newState
    default:
      return state;
  }
}
