export default function growingAreaId(state = {}, action) {
  let newState = {}

  if (action.type == 'UPDATE_GROWING_AREA_ID') {
    // state.push(action.data) // array

    newState[action.data] = []

    // Object.assign({}, state, newState)
    // state[action.data] = {}

    return Object.assign({}, state, newState);

  } else if (action.type == 'UPDATE_GROWING_AREA_PLANTING') {
    // FIXME: do not mutate state! should recreate it
    if (state[action.data.growingAreaId] == undefined) {
      state[action.data.growingAreaId] = []
    }
    state[action.data.growingAreaId].push(action.data.plantingId)
    
    return state

  } else if (action.type == 'SET_GROWING_AREA_IDS') {
    return action.data

  } else if (action.type == 'CLEAR_GROWING_AREA_IDS') {
    return []

  } else {
    return state;
  }
}
