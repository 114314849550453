import { loggedIn, loggedOut } from '../actions/Authenticate'
import { showAlert, showSuccess } from '../actions/Alert'
import LoginForm from '../scenes/LoginForm'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedIn: () => {
      dispatch(loggedIn());
      dispatch(push('/diets'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));

    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    }

  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(
  LoginForm
)
