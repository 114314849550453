export function updateDiets(diet) {
  return { type: 'DIETS.UPDATE', data: diet }
}

export function setDiets(diets) {
  return { type: 'DIETS.SET', data: diets }
}

export function destroyDiet(dietId) {
  return { type: 'DIETS.DELETE', data: dietId}
}