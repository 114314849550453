export function updateGrowingArea(growingArea) {
  return { type: 'UPDATE_GROWING_AREA', data: growingArea }
};

export function setGrowingAreas(growingAreas) {
  return { type: 'SET_GROWING_AREAS', data: growingAreas}
}

export function destroyGrowingArea(growingAreaId) {
  return {
    type: 'GROWING_AREAS.DELETE',
    data: growingAreaId
  }
}