export function updateCrops(crop) {
  return { type: 'CROPS.UPDATE', data: crop }
}

export function setCrops(crops) {
  return { type: 'CROPS.SET', data: crops }
}

export function destroyCrop(cropId) {
  return {
    type: 'CROPS.DELETE',
    data: cropId
  }
}