
import { connect } from 'react-redux'
import Contact from '../scenes/Contact'

function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(
  Contact
)
