import { loggedIn, loggedOut } from '../actions/Authenticate'
import Diets from '../components/Diets'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    diet: state.diet,
    diets: state.diets
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    }
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(
  Diets
)
