import PlantingsTable from '../components/PlantingsTable'
import { connect } from 'react-redux'
import { setGrowingAreas } from '../actions/GrowingArea'
import { setPlantings } from '../actions/Planting'
import { updateGrowingAreaId, clearGrowingAreaIds } from '../actions/GrowingAreaId'
import { updateGrowingAreaPlanting } from '../actions/GrowingAreaId'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    diets: state.diets,
    plantings: state.plantings,
    growingAreas: state.growingAreas
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setGrowingAreas: (growingAreas) => {
      dispatch(setGrowingAreas(growingAreas));
    },

    setPlantings: (data) => {
      dispatch(setPlantings(data))
    },

    updateGrowingAreaId: (growingAreaId) => {
      dispatch(updateGrowingAreaId(growingAreaId))
    },

    updateGrowingAreaPlanting: (growingAreaId, plantingId) => {
      dispatch(updateGrowingAreaPlanting(growingAreaId, plantingId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlantingsTable);
