import React, { Component } from 'react'
import AlertContainer from 'react-alert'

export default class ChangePassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      passwordValue: '',
      passwordConfValue: '',
      reset_password_token: '',
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfChange = this.handlePasswordConfChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePasswordChange(event){
    this.setState({passwordValue: event.target.value});
  }

  handlePasswordConfChange(event) {
    this.setState({passwordConfValue: event.target.value});
  }

  showAlert = (msg) => {
    this.msg.show(msg, {
      position: 'bottom right',
      time: 2000,
      type: 'error',
    })
  }

  showSuccess = (msg) => {
    this.msg.show(msg, {
      position: 'bottom right',
      time: 5000,
      type: 'success',
      transition: 'fade',
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    let pass = this.state.passwordValue;
    let passConf = this.state.passwordConfValue
    const reset_token = document.location.href.split('?')[1].split('reset_token=')[1];

    fetch('/api/users/password', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          password: pass,
          password_confirmation: passConf,
          reset_password_token: reset_token
        }

      })
    })
    .then((resp) => {
      return resp.json();
    })
    .then((response) => {
      this.showSuccess("Password updated")
    })
    .catch((error) => {
      this.showAlert("Unable to update")
    });
  }

  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <AlertContainer ref={a => this.msg = a} />
        <form action="sign-up_submit">
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <legend className="ph0 mh0 fw6 clip">Reset Password</legend>
            <div className="mt3">
              <label className="db fw4 lh-copy f6" >New Password</label>
              <input onChange={this.handlePasswordChange} value={this.state.passwordValue} className="pa2 input-reset ba bg-transparent w-100 measure" type="password" name="password"  id="password"/>
            </div>
            <div className="mt3">
              <label className="db fw4 lh-copy f6" >New Password Confirmation</label>
              <input onChange={this.handlePasswordConfChange} value={this.state.passwordConfValue} className="pa2 input-reset ba bg-transparent w-100 measure" type="password" name="password_confirmation"  id="password_confirmation"/>
            </div>

          </fieldset>
          <div className="mt3">
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6" type="submit" value="Reset Password" onClick={this.handleSubmit} />
          </div>
        </form>
      </section>
    )
  }
}
