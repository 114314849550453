export function setPeople(people) {
  return { type: 'PEOPLE.SET', data: people}
}

export function updatePeople(person) {
  return { type: 'PEOPLE.UPDATE', data: person}
}

export function destroyPerson(personId) {
  return { type: 'PEOPLE.DESTROY', data: personId }
}