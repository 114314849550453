import React, { Component } from 'react';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = { contentHtml: "" };
  }

  // TODO: Why is / failing to fetch correct JSON?
  componentDidMount() {
    fetch('/')
      .then(response => {
        if (response.status == 410) {
          localStorage.removeItem('jwtToken');
          return
        }
        return response.json()
      })
      .then(json => {
        this.setState({ contentHtml: json.html })
      })
      .catch(error => console.log(error))
  }


  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns tc" style={{'marginBottom': '700px'}}>
        <h2>Welcome to Harvest Planner</h2>
        <p>Ensure your harvest can meet the nutritional needs of your family.</p>
      </section>
    );
  }
}

export default Home;
