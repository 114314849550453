import GardenViewDraggable from '../components/GardenViewDraggable'
import { connect } from 'react-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { loggedOut } from '../actions/Authenticate'
import { push } from 'react-router-redux'
import { updateGrowingArea, setGrowingAreas } from '../actions/GrowingArea'
import { setPlantings } from '../actions/Planting'
import { updateGrowingAreaId, clearGrowingAreaIds } from '../actions/GrowingAreaId'
import { updateGrowingAreaPlanting } from '../actions/GrowingAreaId'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    diets: state.diets,
    growingAreas: state.growingAreas,
    plantings: state.plantings,
    growingAreaIds: state.growingAreaIds
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    setGrowingAreas: (growingAreas) => {
      dispatch(setGrowingAreas(growingAreas));
    },

    setPlantings: (plantings) => {
      dispatch(setPlantings(plantings))
    },

    updateGrowingAreaId: (growingAreaId) => {
      dispatch(updateGrowingAreaId(growingAreaId))
    },

    clearGrowingAreaIds() {
      dispatch(clearGrowingAreaIds())
    },

    updateGrowingAreaPlanting: (growingAreaId, plantingId) => {
      dispatch(updateGrowingAreaPlanting(growingAreaId, plantingId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GardenViewDraggable)
