import React, { Component } from 'react';
import displayErrorMessages from '../utilities/DisplayErrorMessages'

const API_DIETS_PATH = '/api/diets'
const API_DIETS_NEW_PATH = '/api/diets/new'

export default class NewDiet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      area_option_id: '', // unit
      crop_yield_option_id: '', // yield estimate
      crop_yield_options: [],
      area_options: [],

      showNewDietForm: false,
    };
  }

  handleNameChange = event =>  {
    this.setState({name: event.target.value});
  }

  handleCropYieldChange = event => {
    this.setState({crop_yield_option_id: event.target.value});
  }

  handleUnitChange = event => {
    this.setState({area_option_id: event.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();

    let dietName = this.state.name;
    let unit = this.state.area_option_id;
    let yield_id = this.state.crop_yield_option_id;

    fetch(API_DIETS_PATH, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
      },
      body: JSON.stringify({
        diet: {
          name: dietName,
          area_option_id: unit,
          crop_yield_option_id: yield_id
        }
      })
    })
    .then(resp => resp.json())
    .then(response => {
      if (response.errors) {
        displayErrorMessages(this.props, response);

        return
      }
      
      this.props.updateDiets(response.diet)
      this.props.showSuccessMsg('Diet Created')
    })
    .catch((error) => {
      this.props.showErrorMsg('Something went wrong.')
    });
  }

  componentDidMount() {
    fetch(API_DIETS_NEW_PATH, {
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      this.setState({ crop_yield_options: json.crop_yield_options, area_options:  json.area_units.filter(i => i.code == 'imperial')
    })
    })
    .catch(error => console.log(error))
  }

  render() {
    return (
      <form className="measure center" onSubmit={this.handleSubmit}>
        <fieldset id="new_diet" className="ba b--transparent ph0 mh0">
          <legend className="f4 fw6 ph0 mh0">New Diet</legend>
          <div className="mt3">
            <label className="db fw6 lh-copy f6">Diet Name</label>
            <input onChange={this.handleNameChange} value={this.state.nameValue} className="pa2 input-reset ba bg-transparent w-80" type="name" name="name"  id="name" />
          </div>
          <div className="mv3">
            <label className="db fw6 lh-copy f6">Unit</label>
            <select onChange={this.handleUnitChange} value={this.state.unitValue} ref="userInput" className="pa2 ">
              <option value="" ></option>
              <option value="1">Metric</option>
              <option value="2">Imperial</option>
              {/* {
                this.state.area_options.map(function(opt) {
                  return <option key={opt.id}
                    value={opt.id}>{opt.code}</option>;
                })
              } */}
            </select>
          </div>
          <div className="">
            <label className="db fw6 lh-copy f6">Yield Estimate</label>
            <select onChange={this.handleCropYieldChange} value={this.state.crop_yield_option_id} ref="userInput" className="pa2 ">
              <option value="" ></option>
              {
                this.state.crop_yield_options.map(function(opt) {
                  return <option key={opt.id}
                    value={opt.id}>{opt.description}</option>;
                })
              }
            </select>
          </div>
        </fieldset>
        <div className="">
          <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Create Diet" />
        </div>
      </form>
    );
  }
}
