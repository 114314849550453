export default function planting(state = {}, action) {
  if (action.type == 'UPDATE_PLANTING') {
    let planting = {}
    planting[action.data.id] = action.data
    return Object.assign({}, state, planting);

  } else if (action.type == 'SET_PLANTINGS') {
    return Object.assign({}, state, action.data);
  
  } else if (action.type == 'PLANTINGS.DELETE') {
    let newObj = {}
    Object.assign(newObj, state)
    delete newObj[action.data]
    
    return newObj
  } else {
    return state;
  }
}
