export function updateCropIds(crop) {
  return { type: 'CROP_IDS.UPDATE', data: crop }
}

export function setCropIds(crops) {
  return { type: 'CROP_IDS.SET', data: crops }
}

export function destroyCropId(cropId) {
  return {
    type: 'CROP_IDS.DELETE',
    data: cropId
  }
}