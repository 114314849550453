import { loggedIn, loggedOut } from '../actions/Authenticate'
import { connect } from 'react-redux'
import { updateDiets } from '../actions/Diets'
import { changeDiet } from '../actions/Diet'
import NewDiet from '../components/NewDiet'
import { showAlert, showSuccess } from '../actions/Alert'
import { push } from 'react-router-redux'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    diets: state.diets
  }
}

function mapDispatchToProps(dispatch) {
  return {

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updateDiets: (diet) => {
      let path = `/diets/${diet.id}`;
      dispatch(updateDiets(diet))
      dispatch(changeDiet(diet.id))
      dispatch(push(path));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDiet);
