import React, { Component } from 'react'
import Draggable from 'react-draggable'
import ModalStyles from '../styles/TallModal.js'
import ReactModal from 'react-modal'
import PlantCropForm from '../containers/PlantCropFormView'
import moment from 'moment'
import { Icon } from "@blueprintjs/core"

const SIZE_MULTIPLIER = 41

export default class Planting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showEditPlanting: false
    }
  }
  handlePlantingStop = (e, data) => {
    let planting = Object.assign(
      {},
      this.props.plantings[this.props.id],
      { position_x: data.lastX, position_y: data.lastY }
    )
    // update store
    this.props.updatePlanting(planting)
  }

  destroyPlanting = () => {
    fetch(`/api/plantings/${this.props.id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(resp => {
      if (resp.status === 204) {

        this.props.destroyPlanting(this.props.id)
        this.props.showSuccessMsg('Planting removed');
      }
      
    })
    .catch(error => {
      
    })
  }

  editPlanting = () => {
    this.setState({
      showEditPlanting: true
    })
  }

  hideEditPlantingModal = () => {
    this.setState({
      showEditPlanting: false
    })
  }

  render() {
    const currentPlanting = this.props.plantings[this.props.id]
    const cropPercentage = (currentPlanting.area_planted / Number(this.props.areaAvailable) ) * 100;
    // let plantingClasses = (currentPlanting.area_planted_h > currentPlanting.area_planted_w) ? "long-cropbox" : "cropbox"
    const plantedDate = moment(currentPlanting.planted_at)
    const start = moment().startOf('week')
    const end = moment().endOf('week')
    const area_in = this.props.growingAreas[this.props.growingAreaId].area_option_id == 1 ? "sq. m." : "sq. ft."
    
    // Planting = during the planting week
    const withinCurrentWeek = plantedDate.isBetween(start, end);
    const statusPlant = withinCurrentWeek ? 'status-plant' : '';
    
    // Harvest = After growing period ends
    const harvestPeriod = plantedDate.add(currentPlanting.crop_weeks_in_ground, 'weeks')
    const duringHarvest = moment().isAfter(harvestPeriod)
    const statusHarvest = duringHarvest ? 'status-harvest' : '';

    // Growing = between end of planted at week and start of harvest
    const growing = moment().isBetween(moment(currentPlanting.planted_at).endOf('week'), harvestPeriod);
    const statusGrowing = growing ? 'status-growing' : ''

    const plantingClasses = `cropbox cropbox--${currentPlanting.usage} ${statusPlant} ${statusHarvest} ${statusGrowing}`;

    // let currentPlantingW = currentPlanting.area_planted_w <= 10 ? '30%' : currentPlanting.area_planted_w
    // let currentPlantingH = currentPlanting.area_planted_h <= 10 ? '30%' : currentPlanting.area_planted_h
    return(
      <span>
        <ReactModal
          isOpen={this.state.showEditPlanting}
          contentLabel='Edit Crop'
          style={ModalStyles}
        >
        <button
            className="f7 link dim  ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.hideEditPlantingModal}>
            Close
          </button>
          { this.state.showEditPlanting ?
            <PlantCropForm
              methodType='PATCH'
              plantingId={this.props.id}
              growingAreaId={this.props.growingAreaId}
              harvestId={currentPlanting.harvest_id}
              areaHeightValue={currentPlanting.area_planted_h}
              areaWidthValue={currentPlanting.area_planted_w}
              areaValue={currentPlanting.area_planted}
              usage={currentPlanting.usage}
              plantedAt={currentPlanting.planted_at}
              onRequestClose={ this.hideEditPlantingModal }
              onFormSubmit={ this.hideEditPlantingModal }
              updateSelection={() => {}} /> 
          : null }

        </ReactModal>
        <Draggable
          // bounds=".base-box"
          onStop={this.handlePlantingStop}
          defaultPosition={{x: currentPlanting.position_x, y: currentPlanting.position_y }}
          // position={{x: currentPlanting.position_x, y: currentPlanting.position_y }}
        > 
          <span className={plantingClasses} style={{width: `${(currentPlanting.area_planted_w * SIZE_MULTIPLIER)-30}px`,height: `${(currentPlanting.area_planted_h * SIZE_MULTIPLIER)-30}px`}}>
            <span className='planting-controls fr'>
              <a className='link white' onClick={() => { this.editPlanting() }}>
                <Icon icon="edit"/>
              </a>
              <a className='destroy-icon link white pl2' onClick={() => this.destroyPlanting()}>
                <Icon icon="small-cross"/>
              </a>
            </span>
            <div className='planting-name'> 
              {currentPlanting.crop_name} 
              <span className="pl1">({currentPlanting.area_planted} {area_in})</span>
            </div>
            
          </span>
      
        </Draggable>
      </span>
    )
  }

}
