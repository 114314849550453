import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import displayErrorMessages from '../utilities/DisplayErrorMessages.js'
import AlertContainer from 'react-alert'

const PLANTINGS_API_ROUTE = '/api/plantings';

export default class PlantCropForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      growingAreaOptions: [],
      cropOptions: [],
      growingBedValue: this.props.growingAreaId,
      cropValue: this.props.harvestId,
      areaValue: this.props.areaValue || 0,
      areaHeightValue: this.props.areaHeightValue || 0,
      areaWidthValue: this.props.areaWidthValue || 0,
      areaUnitsValue: this.props.diets[this.props.diet.id].area_option_id,
      plantedOnValue: this.props.plantedAt ? moment(this.props.plantedAt) : moment(),
      dietId: document.location.pathname.split('/')[2], // TODO: this is hacky
      submitBtnText: this.props.methodType == 'PATCH' ? 'Update' : 'Plant crop',
      usageValue: this.props.usage || 0,
    }
  }

  showAlert = (msg) => {
    this.msg.show(msg, {
      position: 'top right',
      time: 5000,
      type: 'error',
      transition: 'fade'
    })
  }

  showSuccess(msg, callbackFn) {
    this.msg.show(msg, {
      position: 'top right',
      time: 5000,
      type: 'success',
      transition: 'fade',
      onClose: callbackFn(this.state.areaValue, this.state.areaUnitsValue, this.state.areaWidthValue, this.state.areaHeightValue)
    })
  }

  componentDidMount() {
    fetch(`/api/diets/${this.state.dietId}/harvests`, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      this.setState({ cropOptions: json.harvests })
    })
    .catch(error => console.log(error))


    fetch(`/api/diets/${this.state.dietId}/growing_areas`, {
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      this.setState({ growingAreaOptions: json.growing_areas })
    })
    .catch(error => console.log(error))
  }

  handleAreaUnitsChange = e => {
      this.setState({areaUnitsValue: e.target.value})
  }

  handleSubmit = e => {
    e.preventDefault()

    const methodType = this.props.methodType || 'POST'
    const apiRoute = this.props.methodType == 'PATCH' ? `/api/plantings/${this.props.plantingId}` : PLANTINGS_API_ROUTE
    let plantingBody = {
      planting: {
        growing_area_id: this.state.growingBedValue,
        harvest_id: this.state.cropValue,
        area_planted: this.state.areaHeightValue * this.state.areaWidthValue,
        area_in: this.state.areaUnitsValue,
        planted_at: this.state.plantedOnValue,
        area_planted_h: Number(this.state.areaHeightValue),
        area_planted_w: Number(this.state.areaWidthValue),
        usage: Number(this.state.usageValue)
      }
    }

    fetch(apiRoute, {
      method: methodType,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
      },
      body: JSON.stringify(plantingBody)
    })
    .then(resp => resp.json())
    .then(response => {
      
      if(response.errors) {
        Object.entries(response.errors).forEach(error => {
          this.msg.show(`${error[0]}: ${error[1]}`, {
            position: 'top right',
            time: 5000,
            type: 'error',
            transition: 'fade'
          })
        })
        return
      }
      // if (response.jwt === null || response.jwt === undefined) {
      //   displayErrorMessages(this.props, response);
      //   return
      // }
      // Add to plantings store
      this.props.updatePlanting(response.planting)
      // Update page context growing area ids
      this.props.updateGrowingAreaPlanting(response.planting.growing_area_id, response.planting.id)

      // if (this.props.methodType == 'PATCH') {
      //   this.props.showSuccessMsg('Planting Updated')
      // } else {
      //   this.props.showSuccessMsg('Planted')
      // }
      this.props.onFormSubmit()
      
    })
    .catch(error => {
      // this.props.showErrorMsg(error)
      // this.msg.show(error)
    });
  }

  handleAreaHeightChange = e => {
    this.setState({areaHeightValue: e.target.value})
  }

  handleAreaWidthChange = e => {
    this.setState({areaWidthValue: e.target.value})
  }

  handleCropChange = e => {
    this.setState({ cropValue: e.target.value })
  }

  growingBedChange = e => {
    this.setState({ growingBedValue: e.target.value })
  }

  plantedOnChange = date => {
    this.setState({ plantedOnValue: date })
  }
  handleUsageChange = e => {
    this.setState({ usageValue: e.target.value })
  }

  render() {
    let growArea = this.props.growingAreas[this.props.growingAreaId]
    const areaUnitId = this.props.diets[this.props.diet.id].area_option_id
    const areaUnits = {
      '1': 'sq. m.',
      '2': 'sq. ft.'
    }

    return (
      <article>
          <AlertContainer ref={a => this.msg = a} />

          <form className="measure center" onSubmit={this.handleSubmit}>
          <legend className="f4 fw6 ph0 mh0">Plant Crop</legend>
            <div className="mt3">
              <label className="db fw6 lh-copy f6">Growing Bed Area</label>
              <select disabled={this.props.disableSelections} onChange={this.growingBedChange} value={this.state.growingBedValue} ref="userInput" className="pa2 ">
                <option value="" ></option>
                {
                  this.state.growingAreaOptions.map(function(opt) {
                    return <option key={opt.id}
                      value={opt.id}>{opt.name}</option>;
                  })
                }
              </select>
            </div>
            <div className="mt3">
              <label className="db fw6 lh-copy f6">Crop Name</label>
              <select disabled={this.props.disableSelections} onChange={this.handleCropChange} value={this.state.cropValue} ref="userInput" className="pa2 ">
                <option value="" ></option>
                {
                  this.state.cropOptions.map(function(opt) {
                    return <option key={opt.id}
                      value={opt.id}>{opt.crop_name}</option>;
                  })
                }
              </select>
            </div>
            {/* <p><i>Available Area {growArea && growArea.area_remaining} {areaUnits[areaUnitId]}</i></p> */}
            <div className='mt3'>
              
              <label className="db fw6 lh-copy f6">Area Width</label>
              <input onChange={this.handleAreaWidthChange} placeholder='0' value={this.state.areaWidthValue} className="pa2 input-reset ba bg-transparent w-30"  />
              <label className="db fw6 lh-copy f6">Area Length</label>
              <input onChange={this.handleAreaHeightChange} placeholder='0' value={this.state.areaHeightValue} className="pa2 input-reset ba bg-transparent w-30"  />
              <div className="mt3">
                <label className="db fw6 lh-copy f6">Units</label>
                <select onChange={this.handleAreaUnitsChange} value={this.state.areaUnitsValue} ref="userInput" className="pa2" disabled={true}>
                  <option value='2'>Sq Ft.</option>
                  <option value='1'>Sq M.</option>
                </select>
              </div>
            </div>

            <div className="mt3">
              <label className="db fw6 lh-copy f6">Plant on date</label>
              <DatePicker
                minDate={moment()}
                selected={this.state.plantedOnValue}
                onChange={this.plantedOnChange}
                className='pa2 input-reset ba bg-transparent w-60'
              />
            </div>

            <div className="mt3">
              <label className="db fw6 lh-copy f6 pa">Usage</label>
              <select onChange={this.handleUsageChange} value={this.state.usageValue} ref="userInput" className="pa2">
                <option value='0'>Eat</option>
                <option value='1'>Sell</option>
                <option value='2'>Compost</option>
              </select>
            </div>
            <div className='mt3'>
              <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value={this.submitBtnText} />
            </div>
          </form>
      </article>
    )
  }
}
