import React, { Component } from 'react'
import displayErrorMessages from '../utilities/DisplayErrorMessages.js'

export default class GrowingAreaForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      growingAreaNameValue: '',
      areaAvailableHeightValue: '5',
      areaAvailableWidthValue: '20',
      unitValue: this.props.diets[this.props.diet.id].area_option_id,
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleUnitChange = this.handleUnitChange.bind(this)
    this.handleAreaAvailableHeightChange = this.handleAreaAvailableHeightChange.bind(this);
    this.handleAreaAvailableWidthChange = this.handleAreaAvailableWidthChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    let props = this.props;

    e.preventDefault()

    let growingAreaName = this.state.growingAreaNameValue
    let areaAvailableHeight = this.state.areaAvailableHeightValue
    let areaAvailableWidth = this.state.areaAvailableWidthValue
    let areaUnit = this.state.unitValue

    fetch(`/api/diets/${this.props.diet.id}/growing_areas`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
      },
      body: JSON.stringify({
        growing_area: {
          name: growingAreaName,
          area_available_h: areaAvailableHeight,
          area_available_w: areaAvailableWidth,
          area_in: areaUnit,
          diet_id: this.props.diet.id,
        }
      })
    })
    .then(resp => resp.json())
    .then(response => {
      // FIXME: remove this and add to previous, check for 401
      if (response.jwt === null || response.jwt === undefined) {
        displayErrorMessages(this.props, response);
      }

      this.props.updateGrowingArea(response.growing_area)
      this.props.updateGrowingAreaId(response.growing_area.id)

      // reset Form UI
      this.setState({
        growingAreaNameValue: '',
        areaAvailableValue: '',
        unitValue: '',
      })

      this.props.showSuccessMsg('Growing Area Created');

      this.props.formSubmitted();
    })
    .catch((error) => {
      // show error msg
    });
  }

  handleAreaAvailableHeightChange(e) {
    this.setState({areaAvailableHeightValue: e.target.value})
  }

  handleAreaAvailableWidthChange(e) {
    this.setState({areaAvailableWidthValue: e.target.value})
  }

  handleUnitChange(e) {
    this.setState({unitValue: e.target.value})
  }

  handleNameChange(e) {
    this.setState({growingAreaNameValue: e.target.value})
  }
  render() {
    return (
      <form className="measure center" onSubmit={this.handleSubmit}>
        <legend className="f4 fw6 ph0 mh0">Add Growing Area</legend>
        <div className="mt3">
          <label  className="db fw6 lh-copy f6">Name</label>
          <input onChange={this.handleNameChange} value={this.state.growingAreaNameValue} className="pa2 input-reset ba bg-transparent w-80"  />
        </div>
       
        <div className="mt3">
          <label className="db fw6 lh-copy f6">Area Width</label>
          <input type="number" onChange={this.handleAreaAvailableWidthChange} value={this.state.areaAvailableWidthValue} className="pa2 input-reset ba bg-transparent w-30" />
        </div>
        <div className="mt3">
          <label className="db fw6 lh-copy f6">Area Length</label>
          <input type="number" onChange={this.handleAreaAvailableHeightChange} value={this.state.areaAvailableHeightValue} className="pa2 input-reset ba bg-transparent w-30" />
        </div>
        <div className="mt3">
          <label className="db fw6 lh-copy f6">Area Units</label>
          <select onChange={this.handleUnitChange} value={this.state.unitValue} ref="userInput" className="pa2" disabled="true">
            <option value='2'>Sq Ft.</option>
            <option value='1'>Sq M.</option>
          </select>
        </div>

        <div className='mt3'>
          <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Create Growing Area" />
        </div>
      </form>
    )
  }
}
