import React, { Component } from 'react';
import '../styles/nutritional-status.css'
import { Card, Elevation, Radio, RadioGroup } from "@blueprintjs/core";

export default class NutritionalStatus extends Component {
  constructor(props) {
    super(props)
    // const person_id = Object.keys(props.people)[0]

    this.state = {
      person: "",
      nutrients: [],
      cropsChanged: false
      // _crops: props.crops,
    }
  }
  componentWillUpdate() {
    // this.getPersonNutrients(this.state.person)
  }

  componentDidUpdate(prevProps, prevState) {
    
    // For testing
    // Object.entries(this.props).forEach(([key, val]) => {
    //   prevProps[key] !== val && console.log(`Prop '${key}' changed`)

    // });
    // if (this.state) {
    //   Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val && console.log(`State '${key}' changed`)
    //   );
    // }
    // console.log(this.state.person)
  }

  handlePersonChange(e) {
    this.setState({
      person: e.target.value,
    })
    
    this.getPersonNutrients(e.target.value)
    this.props.setPerson(e.target.value)
    
  }

  getPersonNutrients(p) {
    if(!p.length) {
      return
    }
    fetch(`/api/people/${p}/nutrients`, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(resp => resp.json())
    .then(parsedResp => {
      this.props.setNutrientLimits(parsedResp)
    })
  }

  componentDidMount() {
    fetch(`/api/nutrients/columns`, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(resp => {
      return resp.json()
    })
    .then(response => {
      this.setState({
        nutrients: response.nutrients
      })
      // if only one person
      if (Object.keys(this.props.people).length == 1) {
        this.setState({
          person: Object.keys(this.props.people)[0]
        })
        this.getPersonNutrients(this.state.person)

      }
      if(this.props.person.id) {
        this.setState({
          person: Object.keys(this.props.people)[0]
        })
        this.getPersonNutrients(this.state.person)

      }
    })
  }

  nutritionScore(nutrientLimit) {
    if (!nutrientLimit) {
      return 
    }
    let diagnosis = nutrientLimit.diagnosis
    let consumed = nutrientLimit.total_nutrient_consumed_in_diet
    let min = nutrientLimit.nutrient_min_limit

    // Widths are split into thirds
    let maxWidth = 100;
    let sufficientMax = 66.6;
    let sufficientMin = 33.3;
    let consumedAddition = consumed*.000001

    if (consumedAddition > 60) {
      consumedAddition = 60 // max
    } else{
      consumedAddition * 2
    }

    
    switch(diagnosis) {
      case 'satisfactory':
        return sufficientMin + consumedAddition
        break
      case 'satisfactory_no_max':
        return sufficientMin + consumedAddition
        break
      case 'excessive':
        return maxWidth
        break
      case 'deficient':
        return 5 // arbitrary low number
        break
      default:
        
        return 0
    }
  }

  nutrientTotalConsumed(nutrient) {
    let nutrientLimit = this.props.nutrientLimits[this.state.person] && this.props.nutrientLimits[this.state.person].find(n => n.nutrient_type === nutrient.raw)
    
    if(!nutrientLimit) {
      return { barColor: '', position: 0 }
    }
    let diagnosis = nutrientLimit.diagnosis
    let consumedData = {
      'satisfactory_no_max': {
        barColor: 'green',
        position: this.nutritionScore(nutrientLimit)
      },
      'satisfactory': {
        barColor: 'green',
        position: this.nutritionScore(nutrientLimit),
      },
      'excessive': {
        barColor: 'gold',
        position: this.nutritionScore(nutrientLimit)
      },
      'deficient': {
        barColor: 'red',
        position: this.nutritionScore(nutrientLimit)
      }
    }
    console.log(nutrientLimit)
    return consumedData[diagnosis] || { barColor: '', position: 0}
  }

  sortAlpha = () => {
    this.state.nutrients.sort((a,b) => {
      // Raw keys are lowercase and underscored
      return (a.raw < b.raw) ? -1 : (a.raw > b.raw) ? 1 : 0;
    })

    this.forceUpdate()
  }

  sortDeficiency = () => {
    this.state.nutrients.sort((a,b) => {
      let nutrientLimitA = this.props.nutrientLimits[this.state.person] && this.props.nutrientLimits[this.state.person].find(n => n.nutrient_type === a.raw)
      let nutrientLimitB = this.props.nutrientLimits[this.state.person] && this.props.nutrientLimits[this.state.person].find(n => n.nutrient_type === b.raw)

      return this.nutritionScore(nutrientLimitA) - this.nutritionScore(nutrientLimitB)
    })

    this.forceUpdate()
  }

  render() {
    
    return (
      <Card interactive={true} elevation={Elevation.TWO} className="small-column">
        <div className='mb3' style={{padding: '8px'}}>
          <strong>Predicted Nutrition for Annual Harvest</strong>
          <br/>
          <div>
            <small>Number of People to be fed: {Object.keys(this.props.people).length}</small>
          </div>
          <div className='mt2'>
            {
              this.state.person && <em className='red'><small>Some nutrients are deficient (shown in red). Scroll down to see more nutrients.</small></em>
            }
            
          </div>
          <br/>
          <div><strong className='mr1'>Choose Person:</strong></div>
          <br/>
          <select onChange={(e) => this.handlePersonChange(e)} value={this.state.person} ref="" className="pa2 ">
            <option value="" ></option>
            {
              Object.keys(this.props.people).map(personId => {
                return <option key={personId}
                  value={personId}>{this.props.people[personId].name}</option>;
              })
            }
          </select>
          <br/>
          <br/>
          <div>
            <div><strong>Sort by:</strong></div>
            <br/>
            
            <input type="radio" name="gender" value="alpha" defaultChecked onClick={this.sortAlpha}  style={{fontSize: '12px'}}/> Alpha 
            <input className="ml3" type="radio" name="gender" value="deficiency" onClick={this.sortDeficiency}  style={{fontSize: '12px'}}/> Deficiency
          </div>
          <br/>
          { Object.entries(this.props.people).length == 0 ?  <div className="bp3-callout bp3-intent-warning mb2">Please Add Person</div> : "" 
          }
          <br/>
          
          
        </div>
       
        <div className='mt2' style={{fontSize: '12px', padding: '8px'}}>
          <div className='mb4'>
            <div className='nutritional-title' style={{color: 'red'}}>Deficient</div>
            <div className='nutritional-title' style={{color: 'green'}}>Sufficient</div>
            <div className='nutritional-title' style={{color: 'gold'}}>Excessive</div>
          </div>
          <hr></hr>
          {
            this.state.person && this.state.nutrients.map(nutrient => {
              return (
                <div key={nutrient.raw} style={{paddingBottom: '6px'}}>
                  <div className='p1'>{nutrient.humanized}</div>
                  <div style={{
                    height: '8px',
                    background: `${this.nutrientTotalConsumed(nutrient).barColor}`,
                    width: `${this.nutrientTotalConsumed(nutrient).position}%`
                  }}></div>
                </div>
              )
            })
          }
        </div>
      </Card>
    )
  }
}
