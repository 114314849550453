import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AlertContainer from 'react-alert'

export default class Join extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailValue: '',
      passwordValue: '',
      passwordConfirmationValue: '',
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert.bind(this)
  }

  handleEmailChange(event){
    this.setState({emailValue: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({passwordValue: event.target.value});
  }

  handlePasswordConfirmationChange(event) {
    this.setState({passwordConfirmationValue: event.target.value});
  }

  showAlert = (msg) => {
    this.msg.show(msg, {
      position: 'top right',
      time: 5000,
      type: 'error',
      transition: 'fade'
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    let email = this.state.emailValue;
    let password = this.state.passwordValue;
    let passwordConf = this.state.passwordConfirmationValue;

    fetch('api/users', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
          password_confirmation: passwordConf
        }

      })
    })
    .then((resp) => {
      return resp.json();
    })
    .then((response) => {
      if (response.jwt === null || response.jwt === undefined) {
        if (response.errors.email) this.showAlert(`email ${response.errors.email}`)
        if (response.errors.password) this.showAlert(`password ${response.errors.password}`)
        if (response.errors.password_confirmation) this.showAlert(`password confirmation ${response.errors.password_confirmation}`)
        return
      }

      localStorage.setItem('jwtToken', response.jwt);

      return fetch('/api/jwt_token', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + response.jwt,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then((resp) => {
        // FIXME: how to redirect via react?
        window.location = '/diets'
      });
    })
    .catch((error) => {
      // FIXME: show validation errors
      console.error(error);
    });
  }

  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <AlertContainer ref={a => this.msg = a} />

        <form className="measure center" onSubmit={this.handleSubmit}>
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <legend className="f4 fw6 ph0 mh0">Sign Up</legend>
            <div className="mt3">
              <label  className="db fw6 lh-copy f6">Email</label>
              <input onChange={this.handleEmailChange} value={this.state.emailValue} className="pa2 input-reset ba bg-transparent    w-100" type="email" name="email-address"  id="email-address" />
            </div>
            <div className="mv3">
              <label  className="db fw6 lh-copy f6">Password</label>
              <input onChange={this.handlePasswordChange} value={this.state.passwordValue} className="b pa2 input-reset ba bg-transparent    w-100" type="password" name="password"  id="password" />
            </div>
            <div className="mv3">
              <label  className="db fw6 lh-copy f6">Password Confirmation</label>
              <input onChange={this.handlePasswordConfirmationChange} value={this.state.passwordConfirmationValue} className="b pa2 input-reset ba bg-transparent    w-100" type="password" name="password"  id="password_confirmation" />
            </div>
          </fieldset>
          <div className="">
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Sign Up" />
          </div>
          <div className="lh-copy mt3">
            <Link className='f6 link dim black db' to='/login' title="Login">Sign In</Link>
          </div>
        </form>
      </section>
    )
  }
}
