import React, { Component } from 'react'
import AlertContainer from 'react-alert'

export default class ResetPassword extends Component {

  constructor(props){
    super(props);

    this.state = {
      emailValue: '',
      resetPassBtnText: 'Reset Password'
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert.bind(this)
  }

  handleEmailChange(event){
    this.setState({emailValue: event.target.value});
  }

  showAlert = (msg) => {
    this.msg.show(msg, {
      position: 'bottom right',
      time: 2000,
      type: 'error',
    })
  }

  showSuccess = (msg, callbackFn) => {
    this.msg.show(msg, {
      position: 'bottom right',
      time: 5000,
      type: 'success',
      transition: 'fade',
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    let email = this.state.emailValue;
    this.setState({resetPassBtnText: 'Resetting...'})
    fetch('api/users/password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: email
        }

      })
    })
    .then((resp) => {
      if (resp.status == 204) {
        this.showSuccess("Email sent")
        this.setState({resetPassBtnText: 'Reset Password'})
        return
      }
      return resp.json();
    })
    .then((response) => {
      if (response.jwt === null || response.jwt === undefined) {
        this.showAlert(response.errors.email)

        return
      }
      localStorage.setItem('jwtToken', response.jwt);

      return fetch('/api/jwt_token', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + response.jwt,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then((resp) => {
        // FIXME: how to redirect via react?
        window.location = '/'
        
      });
    })
    .catch((error) => {
      // FIXME: show validation errors
      console.error(error);
    });
  }

  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <AlertContainer ref={a => this.msg = a} />

        <form onSubmit={this.handleSubmit}>
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <legend className="ph0 mh0 fw6 clip"> </legend>
            <div className="mt3">
              <label className="db fw4 lh-copy f6" >Email address</label>
              <input onChange={this.handleEmailChange} value={this.state.emailValue} className="pa2 input-reset ba bg-transparent w-100 measure" type="email" name="email-address"  id="email-address"/>
            </div>

          </fieldset>
          <div className="mt3">
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6" type="submit" value={ this.state.resetPassBtnText } />
          </div>
        </form>
      </section>
    )
  }
}
