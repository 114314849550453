import _ from 'underscore'

const visibleState = { visible: true }

export default function alert(state = {}, action) {
  let visible = _.extend(visibleState, {
    msg: action.text,
    type: action.type
  })

  if (action.type == 'ERROR') {
    return Object.assign({}, state, visible)
  } else if (action.type == 'SUCCESS') {
    return Object.assign({}, state, visible)
  } else {
    return state;
  }
}
