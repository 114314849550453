import { loggedIn, loggedOut } from '../actions/Authenticate'
import Nav from '../components/Nav'
import { connect } from 'react-redux'

function mapStateToProps(state) {
  return { authenticated: state.authenticated }
}

// no dispatch necessary in this view
// function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, null)(Nav);
