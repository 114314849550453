import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class LogoutButton extends Component {
  constructor(props) {
    super(props)
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleSignOut(event) {
    let props = this.props;

    fetch('api/users/sign_out', {
      method: 'DELETE',
    })
    .then((resp) => {
      // Remove jwt token and redirect
      localStorage.removeItem('jwtToken');

      props.sendLoggedOut();
    })
    .catch((error) => {
      // FIXME: show validation errors
      console.error(error);
    });
  }

  render() {
    return (
      <Link className="f6 link dim br2 ph3 pv2 mb2 dib black bg-white" to='#' title='Sign Out' onClick={this.handleSignOut}>Sign out</Link>
    )
  }
}
