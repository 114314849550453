import AlertMsg from '../components/AlertMsg'
import { connect } from 'react-redux'

function mapStateToProps(state) {
  return {
    visible: state.alert.visible,
    message: state.alert.msg,
    type: state.alert.type
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMsg);
