import { Link } from 'react-router-dom'
import { Button, ButtonGroup} from "@blueprintjs/core";
import DietSelector from '../containers/DietSelectorView.js'
import React, { Component } from 'react'

export default class DietNavBar extends Component {

  handleDietDestroy = () => {
    fetch(`/api/diets/${this.props.diet.id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer  ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(resp => {
      this.props.destroyDiet(this.props.diet.id)
      this.props.showSuccessMsg('Diet removed')

      this.props.redirectTo('/diets')
    })
    .catch(error => this.props.showErrorMsg(error))
  }



  render() {
    const layoutLink = `/diets/${this.props.diet.id}/layout`
    const plantingsTableLink = `/diets/${this.props.diet.id}/table`

    return (
      <section className="pa3 pa3 hp-sub-nav">
          <DietSelector dietVal={this.props.diet.id} cropIds={[]} dietChanged={() => {this.props.dietChange()}}/>
          <span className='pa3'>
            <ButtonGroup style={{ minWidth: 200 }}>
              <Link to={layoutLink} title='Layout Tool'>
                <Button>Garden View</Button>
              </Link>
              <Link to={plantingsTableLink} title='Plantings Table'>
                <Button>Table View</Button>
              </Link>
            </ButtonGroup>
            <Button icon="small-cross" intent="danger" text="Delete Diet" onClick={this.handleDietDestroy} className="fr"></Button>
          </span>
        </section>
    )
  }
}
