export default function growingArea(state = {}, action) {
  if (action.type == 'UPDATE_GROWING_AREA') {
    let ga = {}
    ga[action.data.id] = action.data
    return Object.assign({}, state, ga);

  } else if (action.type == 'SET_GROWING_AREAS') {
    return Object.assign({}, state, action.data);

  } else if (action.type == 'GROWING_AREAS.DELETE') {
    let newState = {}
    Object.assign(newState, state)
    delete newState[action.data]
    return newState
  } else {
    return state;
  }
}
