export default function authenticated(state = {}, action) {
  console.log(action)
  if (action.type == 'LOGGED_IN') {
    // REVIEW: Shouldn't be retrieving from localStorage here?
    return Object.assign({}, state, { jwt: localStorage.getItem('jwtToken') });
  } else if (action.type == 'LOGGED_OUT'){
    return Object.assign({}, state, { jwt: null });
  } else {
    return state; // return previous state for any unknown action
  }

}
