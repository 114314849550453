import React, { Component } from 'react'
import AddPersonForm from '../containers/AddPersonView.js'
import NewCropForm from '../containers/NewCropView.js'
import CropsTableContainer from '../containers/CropsTableContainer.js'
import DietSelector from '../containers/DietSelectorView.js'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal';
import ModalStyles from '../styles/TallModal.js';
import NutritionalStatusContainer from '../containers/NutritionalStatusContainer.js'
import '../styles/columns.css';
import { Button, ButtonGroup} from "@blueprintjs/core";
import DietNavBarView from '../containers/DietNavBarView'

export default class Diet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPersonForm: false,
      addPersonLinkText: '+ Add Person',
      addCropLinkText: '+ Add Crop',
      crops: [],
      // harvests: [],

      showAddPersonModal: false,
      showAddCropModal: false,

      showEditPersonModal: false,
      // hideEditPersonModal: true,
      crop_yield_option_id: '',
      nutritionKey: 1
    }
  }

  updateCropYield = e => {
    e.preventDefault();

    this.setState({loading: true});

    fetch(`/api/diets/${this.props.diet.id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        diet: {
          crop_yield_option_id: e.target.value
        }
      })
    })
    .then(response => {
      if (response.status == 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login.');
        return
      }
      return response.json()
     }
    )
    .then(json => {
      this.getHarvests();
      this.setState({
        loading: false,
        crop_yield_option_id: json.diet.crop_yield_option_id,
      })
      this.props.showSuccessMsg('Diet updated')
    })
    .catch(error => console.log(error))
  }

  updateAreaOption = e => {
    e.preventDefault();
    this.setState({loading: true});

    fetch(`/api/diets/${this.props.diet.id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        diet: {
          area_option_id: e.target.value
        }
      })
    })
    .then(response => {
      if (response.status == 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login.');
      }

      return response.json();
    })
    .then(json => {
      this.props.updateDiet(json.diet)
      this.getDiet()
      this.setState({
        loading: false,
        area_option_id: json.diet.area_option_id,
      })
      this.getHarvests();
      this.forceUpdate()

      this.props.showSuccessMsg('Diet updated')
      
    })
    .catch(error => console.log(error))

  }

  addCropClicked = e => {
    e.preventDefault();

    this.setState({
      showAddCropModal: true,
      showAddCropForm: true
    });
  }

  addPersonClicked = e => {
    e.preventDefault();

    this.setState({
      showAddPersonModal: true,
      showPersonForm: true
    })
  }

  componentDidMount() {
    this.getDiet()
    this.getHarvests()
  }

  hideAddPersonModal = () => {
    this.setState({
      showAddPersonModal: false,
      showPersonForm: false
    })
  }

  hideAddCropModal = () => {
    this.setState({
      showAddCropModal: false,
      showAddCropForm: false
    })
  }

  hideEditPersonModal = () => {
    this.setState({
      showEditPersonModal: false,
      showPersonForm: false,
    })
  }

  getDiet = () => {
    // Use this because props.diet.id doesn't update fast enough on change
    fetch(`/api${document.location.pathname}`, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      
      this.setState({
        name: json.diet.name,
        createdAt: json.diet.created_at,
        crops: json.crops,
        area_option_id: json.diet.area_option_id,
        crop_yield_option_id: json.diet.crop_yield_option_id
      })
      
      const cropIds = json.crops.map(crop => { 
        return crop.id
      })
      // this.props.setCropIds(cropIds)
      // this.props.setCrops(json.crops)
      console.log("people",json.people)
      this.props.setPeople(json.people)
      this.props.updateDiet(json.diet)

    })
    .catch(error => console.log(error))
  }

  getHarvests = () => {
    
    this.setState({loading: true, nutritionKey: this.state.nutritionKey+1});

    fetch(`/api/diets/${this.props.diet.id}/harvests`, {
      headers: {
        'Authorization': `Bearer  ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      const harvestIds = json.harvests.map(harvest => { return harvest.id } )
      this.props.setCrops(json.harvests)
      this.props.setCropIds(harvestIds)
      this.setState({loading: false});
      this.props.showSuccessMsg('Harvest updated')
    })
    .catch(error => this.props.showErrorMsg(error))
  }

  onDietChange = () => {
    console.log("diet changed")
    // update crops
    this.getDiet();
    this.getHarvests();
  }

  onEditPerson = (personId) => {
    this.setState({
      showEditPersonModal: true,
      showPersonForm: true,
      editPersonId: personId,
    })
  }

  handleDietDestroy = () => {
    fetch(`/api/diets/${this.props.diet.id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer  ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(resp => {
      this.props.destroyDiet(this.props.diet.id)
      this.props.showSuccessMsg('Diet removed')

      this.props.redirectTo('/diets')
    })
    .catch(error => this.props.showErrorMsg(error))
  }

  render() {
    const currentDiet = this.props.diets[this.props.diet.id]
    console.log ('person:', this.props.person)

    return (
      <div>
        <DietNavBarView dietChange={this.onDietChange} />
        
        <div className='pa3'>
        { currentDiet ?
          <div>
            <h3>Diet Name: {currentDiet.name}</h3>
   
            <div className="bp3-select mr2">
              <select onChange={this.updateAreaOption} value={currentDiet.area_option_id} ref="userInput">
              <option value='1'>metric</option>
              <option value='2'>imperial</option>
              </select>
            </div>
            <div className="bp3-select mr2">
              <select onChange={this.updateCropYield} value={this.state.crop_yield_option_id} ref="userInput">
                <option value='1'>Conventional Conservative</option>
                <option value='2'>Biointensive Beginning</option>
                <option value='3'>Biointensive Intermediate</option>
                <option value='4'>Biointensive Advanced</option>
              </select>
            </div>
            <Button icon="plus" className="mr2" onClick={this.addPersonClicked}>Add Person</Button>
            <Button icon="plus" onClick={this.addCropClicked}>Add Crop</Button>
            <p></p>
            <div className='two-column-container'>
              <div className="large-column">
                <CropsTableContainer
                  dietId={this.props.diet.id}
                  harvestIds={this.props.cropIds}
                  loading={this.state.loading}
                  editPerson={this.onEditPerson}
                  updateHarvests={this.getHarvests}
                />
              </div>
              <div className="small-column">
                <NutritionalStatusContainer key={this.state.nutritionKey} />
              </div>
            </div>
          </div> : 'Loading Diet...'
        }
          <ReactModal
            isOpen={this.state.showAddPersonModal}
            contentLabel='Add Person'
            style={ModalStyles}
          >
          <button
            className="f6 link dim br2 ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.hideAddPersonModal}>
            Close
          </button>
          { this.state.showPersonForm ?
            <AddPersonForm
              newPerson={true}
              formLegend='Add Person'
              formSubmitted={this.hideAddPersonModal} /> : null }
        </ReactModal>

        <ReactModal
            isOpen={this.state.showEditPersonModal}
            contentLabel='Edit Person'
            style={ModalStyles}
          >
          <button
            className="f6 link dim br2 ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.hideEditPersonModal}>
            Close
          </button>
          { this.state.showPersonForm ?
            <AddPersonForm
              newPerson={false}
              formLegend='Edit Person'
              personId={this.state.editPersonId}
              formSubmitted={this.hideEditPersonModal} /> : null }
        </ReactModal>

        <ReactModal
            isOpen={this.state.showAddCropModal}
            contentLabel='Add Crop'
            style={ModalStyles}
          >
          <button
            className="f6 link dim br-pill ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.hideAddCropModal}>
            Close
          </button>
          { this.state.showAddCropForm ?
            <NewCropForm formLegend='Add Crop' formSubmitted={this.hideAddCropModal} /> : null }
        </ReactModal>
        </div>
      </div>

    )
  }

}
