import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget, DragSource } from 'react-dnd';
import { connect, compose } from 'redux'
import { findDOMNode } from 'react-dom';
import _ from 'underscore'
import imgURL from '../images/background-body.png'
import DraggableBox from './DraggableBox.js'
import shouldPureComponentUpdate from './shouldPureComponentUpdate.js'
import snapToGrid from './snapToGrid'
import update from 'immutability-helper'
import ItemTypes from './ItemTypes'
import Draggable from 'react-draggable'
import PlantingContainer from '../containers/PlantingView'
import { Icon } from "@blueprintjs/core"

const SIZE_MULTIPLIER = 40

const gardenStyles = {
  backgroundColor: '#f2f2f2',
  height: '1000px',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  padding: '0'
}

const style = {
  // height: '10rem',
  // width: '30rem',
  // marginRight: '1.5rem',
  // marginBottom: '1.5rem',
  color: 'white',
  // padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  // lineHeight: 'normal',
  // float: 'left',
  cursor: 'move'
};

const plantingNameStyle = {
  background: 'rgba(255,255,255,.5)',
  padding: '5px',
  borderRadius: '30px',
  color: 'black'
}

const growingAreaTarget = {
  hover(props, monitor, component) {

  if (monitor.getItemType() === 'crop')
    return;

   const dragIndex = monitor.getItem().index;
   const hoverIndex = props.index;

   // Don't replace items with themselves
   if (dragIndex === hoverIndex) {
     return;
   }

   // Determine rectangle on screen
   const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

   // Get vertical middle
   const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

   // Determine mouse position
   const clientOffset = monitor.getClientOffset();

   // Get pixels to the top
   const hoverClientY = clientOffset.y - hoverBoundingRect.top;

   // Only perform the move when the mouse has crossed half of the items height
   // When dragging downwards, only move when the cursor is below 50%
   // When dragging upwards, only move when the cursor is above 50%

   // Dragging downwards
   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
     return;
   }

   // Dragging upwards
   if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
     return;
   }

   // Time to actually perform the action
   props.moveGrowingArea(dragIndex, hoverIndex);

   // Note: we're mutating the monitor item here!
   // Generally it's better to avoid mutations,
   // but it's good here for the sake of performance
   // to avoid expensive index searches.
   monitor.getItem().index = hoverIndex;
  },


  drop(props, monitor) {
    if (monitor.getItemType() === 'growing_area')
      return;

    props.onDrop(monitor.getItem());
  },
};


const growingAreaSource = {
  beginDrag(props) {
    return {
      name: props.name,
      index: props.index,
    };
  },
};


/**
 * Specifies the props to inject into your component.
 */
function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

function collectSource(connect, monitor) {

  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function collectBoxTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

class GrowingArea extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    accepts: PropTypes.arrayOf(PropTypes.string).isRequired,
    lastDroppedItem: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    areaAvailable: PropTypes.string.isRequired,
    plantings: PropTypes.array.isRequired,
    showCopyIcon: PropTypes.bool,
    moveGrowingArea: PropTypes.func.isRequired,
  };

  shouldComponentUpdate = shouldPureComponentUpdate

  constructor(props) {
    super(props);
  }

  handleStop = (e, data) => {
    let ga = Object.assign({}, this.props.growingAreas[this.props.id], {
      x: data.lastX,
      y: data.lastY
    })

    this.props.updateGrowingArea(ga)
  }

  destroyGrowingArea = (id) => {
    fetch(`/api/diets/${this.props.diet.id}/growing_areas/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer  ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      // TODO: DRY this up
      if (response.status === 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login.');
        return
      }
      this.props.destroyGrowingAreaId(id)
      this.props.destroyGrowingArea(id)
      this.props.showSuccessMsg('Growing Area removed')
      this.forceUpdate()
      this.setState({loading: false});
     }
    )
    .catch(error => console.log(error))
  
  }
  
  render() {
    let plantings = this.props.plantings
    let plantingsIds = this.props.plantingsIds

    const {
      accepts,
      isOver,
      canDrop,
      connectDropTarget,
      connectDragSource,
      lastDroppedItem,
      showCopyIcon,
      isDragging } = this.props;
    const isActive = isOver && canDrop;
    const dropEffect = showCopyIcon ? 'copy' : 'move';
    const opacity = (isActive && !_.contains(accepts, 'crop')) ? 0 : 1;
    let bgImg = '';
    let growingAreaWidth;
    let growingAreaHeight;

    let backgroundColor = '#5cb85c';
    if (isActive) {

    } else if (canDrop) {
      backgroundColor = '#5cb85c';
    }

    if (this.props.areaAvailableWidth && this.props.areaAvailableHeight) {
      growingAreaWidth = this.props.areaAvailableWidth;
      growingAreaHeight = this.props.areaAvailableHeight;
    } else {
      // Just divide by 2 for dimensions if no w or h exist
      growingAreaWidth = (this.props.areaAvailable / 2);
      growingAreaHeight = (this.props.areaAvailable / 2);
    }

    return connectDropTarget(
      <div>
        <Draggable
          handle="strong"
          onStop={this.handleStop}
          position={null}
          defaultPosition={{x: this.props.positionX, y: this.props.positionY}}
        >
          <div className='base-box fr no-cursor' style={{ width: growingAreaWidth * SIZE_MULTIPLIER, height: growingAreaHeight * SIZE_MULTIPLIER }}>
            <strong>
              <a className='destroy-icon link white fr growing-area-controls' onClick={() => this.destroyGrowingArea(this.props.id)}>
                <Icon icon="small-cross" />
              </a>
              <span>{this.props.name}: { this.props.areaAvailableWidth }x{ this.props.areaAvailableHeight } </span>
              <span className="pl3">
                <Icon icon="drag-handle-horizontal"  class="" />
              </span>
            </strong>

            { !_.isEmpty(plantings) ?
              Object.keys(plantings).filter(id => {
                // Find only plantings we care about
                return plantingsIds.indexOf(parseInt(id)) > -1
              }).map((key, index) => {
                return(
                  <PlantingContainer
                    key={key}
                    id={key}
                    areaAvailable={this.props.areaAvailable}
                    growingAreaId={this.props.id}
                  />
                )
              })
            : '' // no plantings
            }
          </div>
        </Draggable>
    </div>,
    { dropEffect }
    )
  }
}

// Combine HOCs and export default
// Access the HOC via import using
// import GrowingAreaHOC, { GrowingArea } from '../components/GrowingArea';
const growingAreaHOC = compose(
  DropTarget([ItemTypes.CROP, ItemTypes.GROWING_AREA], growingAreaTarget, collectTarget),
)

export default growingAreaHOC(GrowingArea);
