import LayoutTool from '../components/LayoutTool'
import { connect } from 'react-redux'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    diets: state.diets,
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutTool);
