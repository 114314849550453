import React, { Component } from 'react'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import '../styles/react-table-overrides.css'
import { Link } from 'react-router-dom'
import AlertContainer from 'react-alert'

export default class CropsTable extends Component {
  constructor(props) {
    super(props)

    const cropsPerYrOptions = Array(13).fill().map((x,i)=>i)

    this.state = {
      loading: false,
      cropsPerYrValue: 1,
      cropsPerYrOptions: cropsPerYrOptions,
      harvestMeasureOptions: [
        {label: 'kg', value: 1},
        {label: 'lb', value: 2},
        {label: 'cups', value: 3}
      ]
    }
  }

  handleCropsPerYrChange = harvest_id => {
    // TBD
  }

  handleHarvestMeasureChange = harvest_id => {
    // TBD
  }

  updateCropsPerYear = data => {
    this.setState({loading: true})

    fetch(`/api/harvests/${data.harvest_id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(resp => resp.json())
    .then(response => {
      this.setState({loading: false});

      this.props.updateCrop(response.crop)
      this.msg.show("Updated")
    })
    .catch(error => {
      this.setState({loading: false});
      // this.msg.show("")
    })
  }

  updatePortion = (data) => {
    this.setState({loading: true});
    
    fetch(`/api/portions/${data.id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then( response => {
      if (response.status === 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login.');
        return
      }
      this.props.showSuccessMsg('Portion updated')
      this.props.updateHarvests()
      this.forceUpdate()
      this.setState({loading: false});

    }).catch(error => console.log(error))
  }

  renderEditableCropsPerYear = (cellInfo) => {
    return (
      <div
        style={{ width: '55px', backgroundColor: "#ffffff", border: '1px solid #ddd', paddingLeft: '5px' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          let data = {
            crops_per_year: e.target.textContent,
            crop_id: cellInfo.original.crop_id,
            harvest_id: cellInfo.original.id,
            diet_id: this.props.diet.id
          }
          this.updateCropsPerYear(data)
          
        }}
        dangerouslySetInnerHTML={{
          __html: cellInfo.value
        }}
      />
    )
  }

  renderEditableAmount = (cellInfo) => {
    return (
      <div
        style={{ width: '55px', backgroundColor: "#ffffff", border: '1px solid #ddd', paddingLeft: '5px' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          let data = {
            person_id: cellInfo.column.id,
            crop_id: cellInfo.original.crop_id,
            amount: e.target.textContent,
            id: cellInfo.original[`${cellInfo.column.id}_portionId`]
          }
          this.updatePortion(data)
          // this.props.updateHarvests()
        }}
        dangerouslySetInnerHTML={{
          __html: cellInfo.value
        }}
      />
    );
  }

  isLoading = () => {
    return this.state.loading || this.props.loading
  }

  clickPerson = (personId) => {
    // open modal
    this.props.editPerson(personId)
  }

  destroyCrop = (id) => {
    this.setState({loading: true});

    fetch(`/api/harvests/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer  ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      // TODO: DRY this up
      if (response.status === 401) {
        this.props.sendLoggedOut();
        this.props.showErrorMsg('Please login.');
        return
      }
      this.props.destroyCropId(id)
      this.props.destroyHarvest(id)
      this.props.showSuccessMsg('Crop removed')
      this.props.updateHarvests()
      this.forceUpdate()
      this.setState({loading: false});
     }
    )
    .catch(error => console.log(error))
  }

  renderRowActions = (cellInfo) => {
    return <a className='link hover-red' onClick={ () => this.destroyCrop(cellInfo.original.id) }>delete</a>
  }

  componentDidUpdate(prevProps, prevState) {
    // TBD
  }

  componentWillUpdate(nextProps, nextState) {
    // new person was added
    if (Object.keys(this.props.people).length < Object.keys(nextProps.people).length) {
      this.props.updateHarvests()
    }

    // If person was removed
    if (Object.keys(this.props.people).length > Object.keys(nextProps.people).length) {
      this.props.updateHarvests()
    }

    if (this.props.dietId != nextProps.dietId) {
      this.props.updateHarvests()
    }
  }

  render() {
    let crops = this.props.crops;
    let harvests = []
    const _diet = this.props.diets[this.props.diet.id]
    const cropConsumedLabel = _diet.area_option_id == 2 ?  "lbs." : "kg."
    const areaNeededLabel = _diet.area_option_id == 2 ?  "sq. ft." : "sq. m."

    this.props.harvestIds.forEach((cropId, index) => {
      let harvest = crops[cropId]

      if(harvest) {
        let total_crop_consumed = parseFloat(harvest.total_crop_consumed).toFixed(2)
        let c = Object.assign(harvest, {
          area_needed: harvest.total_area_needed.toFixed(2),
          total_crop_consumed: total_crop_consumed,
          
        })
        harvests.push(c)
      }
      
    });
        
    let peopleColumns = Object.keys(this.props.people).map(personId => {
      let person = this.props.people[personId]
      
      harvests.forEach(harvest => {
        if (!person) {
          return
        }
        let portn = person.portions.filter(portion => portion.crop_id === harvest.crop_id)
        if (portn.length) {
          let amount
          console.log(portn[0].amount)
          if (_diet.area_option_id == 2) {
            amount = portn[0].amount.imperial.toFixed(2)
          }
          else {
            amount = portn[0].amount.metric.toFixed(2)
          }
          
          harvest[`${personId}`] = amount
          harvest[`${personId}_portionId`] = portn[0].id
           
        } else {
          // new portion
          let defaultAmount
          if (_diet.area_option_id == 2) {
            defaultAmount = harvest.crop_default_amount.imperial
          } else {
            defaultAmount = harvest.crop_default_amount.metric
          }
          
          harvest[`${personId}`] =  defaultAmount.toFixed(2)
        }
        // Override each harvest
        let idx = harvests.indexOf(harvest)
        harvests[idx] = harvest
      })

      return {
        'Header': () => (
          <div className='tl'>
            <a className="b" onClick={ () => this.clickPerson(person.id) }>{person.name} </a>
            <br/>({cropConsumedLabel} per day)
          </div>
        ),
        'accessor': `${person.id}`,
        width: 150,
        Cell: this.renderEditableAmount
      }
    })

    let initialColumns = [{
      Header: () => ( <div className='tl b'>Crop</div>),
      accessor:'crop_name',
      width: 400,
    }].concat(peopleColumns)
    
    let columns = initialColumns.concat([
      {
        Header: () => ( <div className='tl b'>Total Crops Consumed <br/>({cropConsumedLabel} per day)</div>),
        accessor: 'total_crop_consumed',
        width: 300
      },
      // {
      //   Header: () => ( <div className='tl b'>Crops per year</div>),
      //   accessor: 'crops_per_year',
      //   width: 200,
      //   Cell: this.renderEditableCropsPerYear
      // },
      {
        Header: () => ( <div className='tl b'>Area needed <br/>({ areaNeededLabel } per year) </div>),
        accessor: 'area_needed',
        width: 210
      },

      {
        Header: () => ( <div className='tl b'>Actions</div>),
        accessor: 'actions',
        width: 200,
        Cell: this.renderRowActions //({ row }) => (<a href='javascript:void(0)' className='link red' onClick={ this.destroyCrop }>delete</a>)
      }
    ])
    return (
      <div>
        <AlertContainer ref={a => this.msg = a} />

        <ReactTable
          loading={this.isLoading()}
          loadingText='Loading...'
          noDataText='No crops in this diet just yet'
          data={harvests}
          columns={columns}
          defaultPageSize={10}
          className="large-column -striped -highlight"
        
        />
      </div>
    )
  }
}
