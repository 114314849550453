export default function cropIds(state = [], action) {
  // Remember that the key is that the original in-memory reference is not modified.
  // As long as we make a copy first, we can safely mutate the copy
  let newState = state.slice();

  switch(action.type) {
    case 'CROP_IDS.UPDATE':
      newState.push(action.data)
      return newState;
    case 'CROP_IDS.SET':
      return action.data
    case 'CROP_IDS.CLEAR':
      return Object.assign({}, state, []);
    case 'CROP_IDS.DELETE':
      const idx = newState.indexOf(action.data)
      delete newState[idx]
      
      return newState
    default:
      return state;
  }
}
