import Draggable from 'react-draggable'
import React, { Component } from 'react'
import BoxStyles from '../styles/box.css'
import _ from 'underscore'
import gardenBackground from '../images/background-body.png'
import GrowingAreaForm from '../containers/GrowingAreaFormView.js'
import ReactModal from 'react-modal'
import ModalStyles from '../styles/TallModal.js'
import Crop from '../containers/CropView';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import GrowingAreaHOC, { GrowingArea } from '../containers/GrowingAreaView';
import PlantCropForm from '../containers/PlantCropFormView'
import ItemTypes from './ItemTypes'
import ReactToPrint from 'react-to-print';
import NutritionalStatusContainer from '../containers/NutritionalStatusContainer.js'
import GardenTimelineView from './GardenTimelineView.js'
import { Spinner, Button} from "@blueprintjs/core";
import AlertContainer from 'react-alert';

const PLANTINGS_BULK_UPDATE_API_ROUTE = '/api/plantings/bulk_update'
const gardenStyles = {
  height: '1000px',
  minWidth: '100%',
  position: 'relative',
  overflow: 'hidden',
  padding: '0'
}

class GardenViewDraggable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      growingAreaIds: [],
      plantingsIds: [],

      showGrowingAreaForm: false,
      growingAreas: [],
      crops: [],
      droppedBoxNames: [],
      activeDrags: 0,
      deltaPosition: {
        x: 0, y: 0
      },
      controlledPosition: {
        x: -400, y: 200
      },
      newPlanting: {
        growing_area_id: '',
        harvest_id: '',
        area_planted: '',
        planted_at: '',
        area_in: ''
      },

      showPlantingModal: false,
      showPlantCropForm: false,
    }

    this.onStart = this.onStart.bind(this)
    this.onStop = this.onStop.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
    this.adjustXPos = this.adjustXPos.bind(this)
    this.adjustYPos = this.adjustYPos.bind(this)
    this.onControlledDrag = this.onControlledDrag.bind(this)
    this.onControlledDragStop = this.onControlledDragStop.bind(this)
    this.eventLogger = this.eventLogger.bind(this)

    // FIXME: consolidate these two
    this.handleGrowingAreaOpenModal = this.handleGrowingAreaOpenModal.bind(this);
    this.handleGrowingAreaCloseModal = this.handleGrowingAreaCloseModal.bind(this);
    this.handleModalFormSubmission = this.handleModalFormSubmission.bind(this);

    this.handlePlantingOpenModal = this.handlePlantingOpenModal.bind(this);
    this.handlePlantingCloseModal = this.handlePlantingCloseModal.bind(this);

    this.handleSaveLayout = this.handleSaveLayout.bind(this);

  }

  handleSaveLayout(e) {
    e.preventDefault();
    e.stopPropagation();

    let growingAreaRequest = fetch(`/api/diets/${this.props.diet.id}/growing_areas`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ growing_areas: this.props.growingAreas })
    })
    .then(response => {
      this.msg.show("Saved", {
        position: 'top right',
        time: 2000,
        type: 'success',
      })
      return { status: response.status, modelName: 'Growing Areas' }
    })
    .catch(error => {
    //  this.props.showErrorMsg(error)
     this.msg.show(error, {
      position: 'top right',
      time: 2000,
      type: 'error',
    })
    })

    let plantingsRequest = fetch(PLANTINGS_BULK_UPDATE_API_ROUTE, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ plantings: this.props.plantings })
    })
    .then(response => {
      return { status: response.status, modelName: 'Plantings' }
    })


    Promise.all([growingAreaRequest,plantingsRequest]).then(responses => {
      responses.forEach((response, index) => {

        if (response.status === 204) {
          this.props.showSuccessMsg(`${response.modelName} Updated`)
          return
        }

        if (response.status === 401) {
          this.props.showErrorMsg('Please Login')
          return
        }

        this.props.showErrorMsg(`Whoops, could not update ${response.modelName}`)
      })
    });
  }

  handlePlantingOpenModal () {
    this.setState({ showPlantingModal: true });
  }

  handlePlantingCloseModal () {
    this.setState({ showPlantingModal: false });
  }

  isDropped(crop) {
    return this.state.droppedBoxNames.indexOf(crop.name) > -1;
  }

  // Updates UI open modal form submission
  handleModalFormSubmission (newPlantingArea, newPlantingUnits, newPlantingWidth, newPlantingHeight) {

  }

  handleGrowingAreaOpenModal (e) {
    e.preventDefault();
    this.setState({
      showGrowingAreaModal: true,
      showGrowingAreaForm: true
    });
  }

  handleGrowingAreaCloseModal () {
    this.setState({ showGrowingAreaModal: false });
  }

  eventLogger (e: MouseEvent, data: Object) {
    console.log('Event: ', e);
    console.log('Data: ', data);
  }

  handleDrag(e, ui) {
    const {x, y} = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  onStart() {
    this.setState({activeDrags: ++this.state.activeDrags});
  }

  onStop() {
    this.setState({activeDrags: --this.state.activeDrags});
  }

   // For controlled component
  adjustXPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
  }

  adjustYPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  }

  onControlledDrag(e, position) {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
  }

  onControlledDragStop(e, position) {
    this.onControlledDrag(e, position);
    this.onStop();
  }

  componentDidMount() {
    this.updateGardenView()
  }

  updateGardenView(targetDate) {
    this.setState({ loading: true })

    let growingAreaURL = `/api/diets/${this.props.diet.id}/growing_areas`
    if(targetDate) {
      growingAreaURL = `/api/diets/${this.props.diet.id}/growing_areas?date_after=${encodeURIComponent(targetDate) }`
    }
    
    fetch(growingAreaURL, {
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        date_after: targetDate
      }
    })
    .then(response => response.json())
    .then(json => {
      let ga = {}
      let plantings = {}

      _.each(json.growing_areas, (growingArea) => {
        // Store ID of each growing area of each in local state
        this.props.updateGrowingAreaId(growingArea.id)

        // Store ID of each planting in local state
        _.each(growingArea.plantings, (planting) => {
          this.props.updateGrowingAreaPlanting(growingArea.id, planting.id)
          plantings[planting.id] = planting
        })

        ga[growingArea.id] = _.extend(growingArea, {
          accepts: [ItemTypes.CROP],
          lastDroppedItem: null
        })
      })

      // Update state with initial API call
      this.props.setGrowingAreas(ga);
      this.props.setPlantings(plantings);

      this.setState({ loading: false })
    })
    .catch(error => {
      this.props.showErrorMsg(error);
    })

    // Get crops
    fetch(`/api/diets/${this.props.diet.id}/harvests`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.props.authenticated.jwt}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      const cropOptions = _.map(json.harvests, (obj) => {
        return _.extend(obj, { type: ItemTypes.CROP })
      })
      this.setState({ crops: cropOptions })
    })
    .catch(error => console.log(error))
  }

  handleDrop(index, growingAreaId, crop) {
    const { name, id } = crop;

    this.setState({
      showPlantCropForm: true,
      newPlanting: {
        crop: crop,
        index: index,
        name: name,
        growing_area_id: growingAreaId,
        harvest_id: id,
        area_planted: '',
        planted_at: '',
        area_in: ''
      }
    });

    this.handlePlantingOpenModal();
  }

  componentWillUnmount() {
    this.props.clearGrowingAreaIds();

    // TODO: warn user if unsaved changes
  }

  render() {
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition} = this.state;
    let growingAreaHeight;
    let growingAreaWidth;
    let growingAreaIds = Object.keys(this.props.growingAreaIds)
    let dietUnits
    if(this.props.diets && this.props.diets[this.props.diet.id]) {
      if(this.props.diets[this.props.diet.id].area_option_id == 2) {
        dietUnits = 'sq. ft.'
      } else if (this.props.diets[this.props.diet.id].area_option_id == 1) {
        dietUnits = 'sq. m.'
      }
    }
      

    // GAs should always have these attributes
    // let gas = {}
    // _.each(this.props.growingAreas, (obj) => {
    //   gas[obj.id] = _.extend(
    //     obj, {
    //       accepts: [ItemTypes.CROP],
    //       lastDroppedItem: null,
    //       position_x: null,
    //       position_y: null
    //     })
    // })

    return (
      <div>
        <nav className="pa3 bg-near-white w-100 bt b--light-silver pl3">
         <AlertContainer ref={a => this.msg = a} />
          { _.isEmpty(this.props.growingAreas) ?
            <div className="bp3-callout bp3-intent-warning mb2"><h4 className="bp3-heading">Important!</h4>No growing areas exist for this diet, please add one by clicking "Add Growing Area" below. </div> :
            ""
          }
          
          <Button icon="small-plus" className="mr2" onClick={this.handleGrowingAreaOpenModal}>Add Growing Area</Button>
          <Button icon="saved" className="mr2" onClick={this.handleSaveLayout}>Save</Button>
          <ReactToPrint
            trigger={() => <Button icon="print" text="Print"></Button> }
            content={() => this.componentRef}
          />
          {!_.isEmpty(this.state.crops) ?
            <p className='pt4'>Drag and drop the following crops onto growing areas: </p>
          : <div className="bp3-callout bp3-intent-warning"><h4 className="bp3-heading">Important!</h4>There are no crops in this diet. Please choose Diet View and add one. </div> }
          
        </nav>
        
        <div className='pa2'>
          <div style={{ overflow: 'scroll' }}>
          {!_.isEmpty(this.state.crops) ? this.state.crops.map(({ name, type, crop_name, id, unplanted_area }, index) =>
            <Crop
              showCopyIcon
              name={`${crop_name} (Left to plant: ${unplanted_area.toFixed(2)} ${dietUnits})`}
              type={type}
              isDropped={this.isDropped({name: crop_name, harvest_id: id})}
              id={id}
              key={index}
            />,
          ) : null }
          </div>
          
          
        </div>
        
        { !_.isEmpty(this.props.growingAreas) ?
          <div className='two-column-container'>
          <div className="large-column">
            <div className="box" ref={el => (this.componentRef = el)} style={{...gardenStyles}} >
              <div style={{height: '1000px', width: '100%', overflow: 'scroll'}}>

                { Object.keys(this.props.growingAreas).filter(id => {
                      // Find only growing areas we care about
                      return growingAreaIds.indexOf(id) > -1

                    }).map((key, index) => {
                      const ga = this.props.growingAreas[key]

                      // convert to imperial if necessary
                      // const area_h = ga.area_option_id == "2" ? ga.area_available_h * 2.54 : ga.area_available_h
                      // const area_w = ga.area_option_id == "2" ? ga.area_available_w * 2.54 : ga.area_available_w
                      const gaPlantingIds = this.props.growingAreaIds[ga.id]

                      return (
                      <GrowingAreaHOC
                          key={index}
                          id={ga.id}
                          name={ga.name}
                          areaAvailable={String(ga.area_available)}
                          areaAvailableHeight={ga.area_available_h}
                          areaAvailableWidth={ga.area_available_w}
                          accepts={ga.accepts}
                          lastDroppedItem={ga.lastDroppedItem}
                          onDrop={item => this.handleDrop(index, ga.id, item)}
                          index={index}
                          moveGrowingArea={this.moveGrowingArea || false}
                          plantingsIds={gaPlantingIds}
                          positionX={ga.position_x}
                          positionY={ga.position_y}
                        />
                      )
                  })
                }
              </div>

            </div>
          </div>
          <NutritionalStatusContainer />
        </div>
        : null }
        
         
        <div style={{
          position: 'fixed',
          bottom: '0',
          width:'100%',
          height: 140,
          backgroundColor: '#FAFAFA',
          padding: 20,
          borderTop: '1px solid #CCCCCC',
          boxShadow: '9px 5px 21px black'
          }}>
          <small>CURRENT WEEK DISPLAYED:</small>

          <GardenTimelineView handleChange={(dateSelection) => { this.updateGardenView(dateSelection)}}/>
        </div>
        <ReactModal
           isOpen={this.state.showGrowingAreaModal}
           contentLabel='Add Growing Area'
           style={ModalStyles}
        >
          <button
            className="f7 link dim br-pill ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.handleGrowingAreaCloseModal}>
            Close
          </button>
          { this.state.showGrowingAreaForm ?
            <GrowingAreaForm formSubmitted={this.handleGrowingAreaCloseModal} /> : null }
        </ReactModal>,

        <ReactModal
           isOpen={this.state.showPlantingModal}
           contentLabel='Plant Crop'
           style={ModalStyles}
        >
          <button className="f7 link dim br-pill ba ph3 pv2 mb2 dib" style={{ position:'absolute', right: '20px' }} onClick={this.handlePlantingCloseModal}>Close</button>
          { this.state.showPlantCropForm ?
            <PlantCropForm
              growingAreaId={this.state.newPlanting.growing_area_id}
              harvestId={this.state.newPlanting.harvest_id}
              disableSelections={true}
              onRequestClose={ this.handlePlantingCloseModal }
              onFormSubmit={ this.handlePlantingCloseModal } />
          : null }

        </ReactModal>
      </div>
    );
  }
}


export default DragDropContext(HTML5Backend)(GardenViewDraggable);

