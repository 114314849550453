export default function person(state = {}, action) {
  let newObj = {}

  switch(action.type) {
    case 'PERSON.UPDATE':
      newObj['id'] = action.data
      return Object.assign({}, state, newObj);

    default:
      return state;
  }
}
