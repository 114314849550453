import AddPerson from '../components/AddPerson'
import { connect } from 'react-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { loggedIn, loggedOut } from '../actions/Authenticate'
import { push } from 'react-router-redux'
import { updatePeople, destroyPerson } from '../actions/People'

function mapStateToProps(state) {
  return {
    diet: state.diet,
    authenticated: state.authenticated,
    alert: state.alert,
    people: state.people,
    diets: state.diets,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updatePeople: person => {
      dispatch(updatePeople(person))
    },

    destroyPerson: personId => {
      dispatch(destroyPerson(personId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPerson)
