import { showAlert, showSuccess } from '../actions/Alert'
import GrowingAreaForm from '../components/GrowingAreaForm'
import { connect } from 'react-redux'
import { updateGrowingArea, setGrowingAreas } from '../actions/GrowingArea'
import { updateGrowingAreaId } from '../actions/GrowingAreaId'

function mapStateToProps(state) {
  return {
    diet: state.diet,
    diets: state.diets,
    authenticated: state.authenticated,
    alert: state.alert,
    growingAreas: state.growingAreas,
    plantings: state.plantings,
    growingAreaIds: state.growingAreaIds
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updateGrowingArea: (growingArea) => {
      dispatch(updateGrowingArea(growingArea));
    },

    updateGrowingAreaId: growingAreaId => {
      dispatch(updateGrowingAreaId(growingAreaId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowingAreaForm);
