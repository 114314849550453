export function updatePlanting(planting) {
  return { type: 'UPDATE_PLANTING', data: planting }
};

export function setPlantings(plantings) {
  return { type: 'SET_PLANTINGS', data: plantings}
}

export function destroyPlanting(plantingId) {
  return { type: 'PLANTINGS.DELETE', data: plantingId}
}
