import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'underscore';

const API_DIETS_ROUTE = '/api/diets'

export default class DietSelector extends Component {
  componentDidMount() {
    // Don't call if already exist!
    if (!!this.props.diets.length) {
      return;
    }

    // Get all diets
    fetch(API_DIETS_ROUTE, {
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => { 
      if (response.status == 410) {
        localStorage.removeItem('jwtToken');
        return
      }
      return response.json()
    })
    .then(json => {
      const allDiets = _.map(json.diets, obj => {
        let newDiet = _.extend(obj, {
          clearableValue: false,
          clearable: false
        })

        return newDiet
      })
      this.props.setDiets(allDiets)

    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDietChange = diet => {
    if (!diet) {
      return;
    }

    this.props.changeDiet(diet.value);
    this.props.dietChanged();
  }

  dietsCollection = () => {
    const diets = _.map(this.props.diets, (diet) => {
      return {
        value: diet.id,
        label: diet.name
      }
    })

    return _.sortBy(diets, 'label');
  }

  render() {
    return (
      <span>
        { !_.isEmpty(this.dietsCollection()) &&
          <span>
            <span className='fl w-10 tracked f6'>DIET:</span>
            <span className='fl w-20'>
              <Select
                name="diet"
                value={this.props.diet.id}
                options={this.dietsCollection()}
                onChange={this.handleDietChange}
                placeholder='Select a Diet'
                style={{marginTop: '-4px'}}
              />
            </span>
          </span>
        }
      </span>
    );
  }

}
