import { connect } from 'react-redux'
import PlantCrop from '../components/PlantCropForm'
import { showAlert, showSuccess } from '../actions/Alert'
import { updatePlanting, setPlantings } from '../actions/Planting'
import { updateGrowingAreaPlanting } from '../actions/GrowingAreaId'

function mapStateToProps(state) {
  return {
    diets: state.diets,
    diet: state.diet,
    authenticated: state.authenticated,
    alert: state.alert,
    plantings: state.plantings,
    growingAreas: state.growingAreas,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updatePlanting: (planting) => {
      dispatch(updatePlanting(planting))
    },

    updateGrowingAreaPlanting: (growingAreaId, plantingId) => {
      dispatch(updateGrowingAreaPlanting(growingAreaId, plantingId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlantCrop)
