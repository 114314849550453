import React, { Component } from 'react';
import 'tachyons';
import 'jquery';
import 'react-select/dist/react-select.css';

import {
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';

import Nav from './containers/NavView.js'
import Footer from './components/Footer.js'
import Main from './components/Routes.js'
import AlertMsg from './containers/AlertMsgView.js'
import './styles/app.css'

class App extends Component {
  render() {
    return (
      <div className="app sans-serif">
        <AlertMsg/>
        <Nav/>
        <Main/>
        <Footer/>
      </div>
    );
  }
}

export default App;
