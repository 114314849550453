import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import Authenticated from '../reducers/Authenticated';
import Alert from '../reducers/Alert';
import Diet from '../reducers/Diet';
import Diets from '../reducers/Diets';
import GrowingAreas from '../reducers/GrowingArea';
import planting from '../reducers/Planting';
import GrowingAreaId from '../reducers/GrowingAreaId'
import Crops from '../reducers/Crops'
import CropIds from '../reducers/CropIds'
import People from '../reducers/People';
import NutrientLimits from '../reducers/NutrientLimits';
import Person from '../reducers/Person';

export default combineReducers({
  routing: routerReducer,
  authenticated: Authenticated,
  alert: Alert,
  diet: Diet,
  diets: Diets,
  growingAreas: GrowingAreas,
  plantings: planting,
  growingAreaIds: GrowingAreaId,
  crops: Crops,
  cropIds: CropIds,
  people: People,
  nutrientLimits: NutrientLimits,
  person: Person
})
