import NewCrop from '../components/NewCrop'
import { connect } from 'react-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { push } from 'react-router-redux'
import { loggedIn, loggedOut } from '../actions/Authenticate'
import { updateCrops } from '../actions/Crops'
import { updateCropIds } from '../actions/CropIds'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    crops: state.crops,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    updateCrops: crop => {
      dispatch(updateCrops(crop))
    },

    updateCropIds: id => {
      dispatch(updateCropIds(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCrop)
