import NutritionalStatus from '../components/NutritionalStatus'
import { connect } from 'react-redux'
import { setNutrientLimits } from '../actions/NutrientLimit'
import { updatePerson } from '../actions/Person'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    people: state.people,
    nutrientLimits: state.nutrientLimits,
    diet: state.diet,
    crops: state.crops,
    person: state.person
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNutrientLimits: (limits) => {
      dispatch(setNutrientLimits(limits))
    },

    setPerson: (personId) => {
      dispatch(updatePerson(personId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NutritionalStatus);
