export default function nutrientLimits(state = {}, action) {
  let newObj = {}

  switch(action.type) {
    case 'NUTRIENT_LIMIT.UPDATE':
      newObj[action.data.person_id] = action.data.nutrient_limits
      return Object.assign({}, state, newObj)
    default:
      return state;
  }
}
