import DietSelector from '../components/DietSelector'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { changeDiet } from '../actions/Diet'
import { setDiets } from '../actions/Diets'

function mapStateToProps(state) {
  return {
    routing: state.routing,
    authenticated: state.authenticated,
    diets: state.diets,
    diet: state.diet
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeDiet: (dietId) => {
      let path = `/diets/${dietId}`;
      dispatch(changeDiet(dietId));

      dispatch(push(path));

    },

    setDiets: diets => {
      dispatch(setDiets(diets))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DietSelector)
