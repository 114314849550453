import React, { Component } from 'react';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = { contentHtml: "" };
  }
  componentDidMount() {
    fetch('api/contact')
      .then(response => {
        if (response.status == 410) {
          localStorage.removeItem('jwtToken');
          return
        }
        return response.json()
      })
      .then(json => {
        this.setState({ contentHtml: json.html })
      })
      .catch(error => console.log(error))
  }

  render() {
    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <p className="lh-copy" dangerouslySetInnerHTML={{__html: this.state.contentHtml}}></p>
      </section>
    );
  }
}

export default Contact;
