export default function diets(state = {}, action) {
  let newObj = {}

  if (action.type == 'CHANGE') {
    return Object.assign({}, state, { id: action.id });

  } else if (action.type == 'DIETS.UPDATE') {
    newObj[action.data.id] = action.data
    return Object.assign({}, state, newObj);

  } else if (action.type == 'DIETS.SET') {
    action.data.forEach((diet) => {
      newObj[diet.id] = diet
    })
    return Object.assign({}, state, newObj) 
  } else if (action.type == 'DIETS.DELETE') {
    newObj = state
    delete newObj[action.data]
    return newObj

  } else {
    return state;
  }
}
