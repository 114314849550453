import Planting from '../components/Planting'
import { connect } from 'react-redux'
import { updatePlanting, destroyPlanting } from '../actions/Planting'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    alert: state.alert,
    diet: state.diet,
    growingAreas: state.growingAreas,
    plantings: state.plantings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePlanting: planting => {
      dispatch(updatePlanting(planting))
    },

    destroyPlanting: id => {
      dispatch(destroyPlanting(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planting)
