import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import AlertContainer from 'react-alert';

export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordValue: '',
      emailValue: '',
      signInButtonText: 'Sign In'
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({emailValue: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({passwordValue: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({signInButtonText: 'Signing In...'})

    let email = this.state.emailValue;
    let password = this.state.passwordValue;

    let props = this.props;

    fetch('/api/users/sign_in', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
        }

      })
    })
    .then((resp) => {
      return resp.json();
    })
    .then((response) => {
      if (response.jwt === null || response.jwt === undefined) {
        this.setState({signInButtonText: 'Sign In'})
        // props.showErrorMsg(response.error)
        this.msg.show(response.error, {
          position: 'top right',
          time: 5000,
          type: 'error',
          transition: 'fade'
        })
        return
      }

      this.setState({ authenticated: true });
      localStorage.setItem('jwtToken', response.jwt);

      props.sendLoggedIn();

      return fetch('/api/jwt_token', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + response.jwt,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });
    })
    .catch((error) => {
      props.showErrorMsg(error)
      this.setState({signInButtonText: 'Sign In'})
      // FIXME: show validation errors
      console.error(error);
    });
  }

  render() {
    if (this.state.authenticated) {
      return (
        <div>You are already logged in.</div>
      )
    }

    return (
      <section className="mw5 mw7-ns center pa3 ph5-ns">
        <AlertContainer ref={a => this.msg = a} />

        <form className="measure center" onSubmit={this.handleSubmit}>
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <legend className="f4 fw6 ph0 mh0">Sign In</legend>
            <div className="mt3">
              <label  className="db fw6 lh-copy f6">Email</label>
              <input onChange={this.handleEmailChange} value={this.state.emailValue} className="pa2 input-reset ba bg-transparent    w-100" type="email" name="email-address"  id="email-address" />
            </div>
            <div className="mv3">
              <label  className="db fw6 lh-copy f6">Password</label>
              <input onChange={this.handlePasswordChange} value={this.state.passwordValue} className="b pa2 input-reset ba bg-transparent    w-100" type="password" name="password"  id="password" />
            </div>
          </fieldset>
          <div className="">
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value={this.state.signInButtonText} />
          </div>
          <div className="lh-copy mt3">
            <Link className='f6 link dim black db' to='/join' title="Join">Sign Up</Link>
            <Link className='f6 link dim black db' to='/reset_password' title="Reset Password">Forgot your password?</Link>
          </div>
        </form>
      </section>
    );
  }
}
