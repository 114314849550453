import React, { Component } from 'react'
import Select from 'react-select'
import AlertOptions from '../styles/AlertOptions'
import displayErrorMessages from '../utilities/DisplayErrorMessages.js'
import AlertContainer from 'react-alert';
import nutsIcon from '../images/nutsandseeds.png'
import grainIcon from '../images/grain.png'
import rootIcon from '../images/root.png'
import vegetableIcon from '../images/vegetable.png'
import fruitIcon from '../images/fruit.png'
import beansAndPeasIcon from '../images/beansandpeas.png'

const iconStyles = {
  background: 'linear-gradient(180deg, green, darkgreen)',
  borderRadius: '4px',
  margin: '10px',
  padding: '7px'
}
export default class NewCrop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cropValue: '',
      cropOptions: [],
      selectOptions: [],
      dietId: document.location.pathname.split('/')[2]
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCropValue = this.handleCropValue.bind(this)
  }

  handleSubmit(e) {
  
    e.preventDefault()
    if (!this.state.cropValue) {
      this.props.showErrorMsg("please select option")
      return
    }
    fetch(`/api/diets/${this.state.dietId}/harvests`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
      },
      body: JSON.stringify({
        crop_id: this.state.cropValue,
      })
    })
    .then(resp => resp.json())
    .then((response) => {
      if (response.errors) {
        this.msg.show(response.errors.crop_id, {
          position: 'top right',
          time: 5000,
          type: 'error',
          transition: 'fade'
        })

        return
      }
      // if (response.jwt === null || response.jwt === undefined) {
      //   displayErrorMessages(this.props, response)

      //   return
      // }
      
      this.props.updateCrops(response.crop)
      this.props.updateCropIds(response.crop.id)
      this.props.showSuccessMsg('Crop Added')
      this.setState({cropValue: null })
      this.props.formSubmitted();
    })
    .catch(error => console.log(error))
  }

  handleCropValue(e) {
    if (e) {
      this.setState({ cropValue: e.value })
    } else {
      this.setState({ cropValue: '' })
    }

  }

  componentDidMount() {
    fetch('/api/crops/new', {
      headers: {
        'Authorization': 'Bearer ' + this.props.authenticated.jwt,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => response.json())
    .then(json => {
      this.setState({
        cropOptions: json.crop_options,
      })
    })
    .catch(error => console.log(error))
  }

  handleCategorySelection = categoryId => {
    this.setState({
      selectOptions: this.state.cropOptions[categoryId],
      cropsLoading: true,
      placeholder: "Loading..."
    })

    this.forceUpdate()
    setTimeout(() => {  this.setState({cropsLoading: false, placeholder: "Select..."})}, 350);
  }

  render() {
    return (
      <form className="measure center" onSubmit={this.handleSubmit}>
        <AlertContainer ref={a => this.msg = a} />
        <fieldset id="new_diet" className="ba b--transparent ph0 mh0">
          <legend className="f4 fw6 ph0 mh0">New Crop</legend>
          <div className="mt3 categories">
            <label className="db fw6 lh-copy f6">Crop Name</label>
            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(6)}>
                <img src={nutsIcon} style={{...iconStyles}}/>
              </a>
              Nuts
            </div>

            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(1)}>
                <img src={grainIcon} style={{...iconStyles}}/>
              </a>
              Grains
            </div>

            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(5)}>
                <img src={rootIcon}  style={{...iconStyles}}/>
              </a>
              Roots
            </div>

            <br/>
            <br/>
            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(3)}>
                <img src={vegetableIcon} style={{...iconStyles}}/>
              </a>
              Vegetables
            </div>

            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(2)}>
                <img src={fruitIcon} style={{...iconStyles}}/>
              </a>
              Fruits
            </div>

            <div className="category-box">
              <a href="javascript:void(0)" onClick={() => this.handleCategorySelection(4)}>
                <img src={beansAndPeasIcon} style={{...iconStyles}}/>
              </a>
              Beans/Peas
            </div>

            <Select
              name="crop"
              value={this.state.cropValue}
              options={this.state.selectOptions}
              onChange={this.handleCropValue}
              isLoading={this.state.cropsLoading}
              placeholder={this.state.placeholder}
              className="mt4"
            />
          </div>
          <div className="mt3">
            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Add Crop" />
          </div>

        </fieldset>

      </form>
    )
  }
}
