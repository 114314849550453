import { loggedIn, loggedOut } from '../actions/Authenticate'
import Diet from '../components/Diet'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { showAlert, showSuccess } from '../actions/Alert'
import { setCrops } from '../actions/Crops'
import { setCropIds } from '../actions/CropIds'
import { setPeople } from '../actions/People'
import { destroyDiet, updateDiets} from '../actions/Diets'

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    diet: state.diet,
    diets: state.diets,
    crops: state.crops,
    cropIds: state.cropIds,
    people: state.people,
    person: state.person
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoggedOut: () => {
      dispatch(loggedOut());
      dispatch(push('/'));
    },

    showErrorMsg: (msg) => {
      dispatch(showAlert(msg));
    },

    showSuccessMsg: (msg) => {
      dispatch(showSuccess(msg));
    },

    setCrops: crops => {
      dispatch(setCrops(crops))
    },

    setCropIds: ids => {
      dispatch(setCropIds(ids))
    },

    setPeople: people => {
      dispatch(setPeople(people))
    },

    redirectTo: (path) => {
      dispatch(push(path))
    },

    destroyDiet: dietId => {
      dispatch(destroyDiet(dietId))
    },

    updateDiet: diet => {
      dispatch(updateDiets(diet))

    }
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(
  Diet
)
