import React, { Component } from 'react';
import Select from 'react-select'
import NewDietContainer from '../containers/NewDietContainer.js'
import DietSelector from '../containers/DietSelectorView.js'
import ReactModal from 'react-modal'
import ModalStyles from '../styles/TallModal.js'
import { Button } from "@blueprintjs/core";

class Diets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newDietLinkText: 'Create Diet',
      showModal: false,
    };
  }

  handleNewDietClick = e => {
    e.preventDefault()

    this.setState({
      showNewDietForm: true,
      showModal: true,
    })
  }

  hideModal = e => {
    e.preventDefault();

    this.setState({
      showModal: false,
    })
  }

  render() {
    return (
      <div>
        
      <section className="pa3 hp-sub-nav" style={{ 'marginBottom': '500px'}}>
        <DietSelector dietVal={this.props.diet.id} harvestIds={[]} dietChanged={()=>{}} />
        <span className='pa4'>
          <Button icon="plus" onClick={this.handleNewDietClick}>{this.state.newDietLinkText}</Button>
        </span>
        
        <ReactModal
            isOpen={this.state.showModal}
            contentLabel='Add Diet'
            style={ModalStyles}
          >
          <button
            className="f6 link dim br2 ba ph3 pv2 mb2 dib"
            style={{ position:'absolute', right: '20px' }}
            onClick={this.hideModal}>
            Close
          </button>
          { !!this.state.showNewDietForm && <NewDietContainer/> }
        </ReactModal>
      </section>
      
      </div>
    );
  }
}

export default Diets;
