import React, { Component } from 'react';

class Footer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentYear: new Date().getFullYear()
    }
  }
  render() {
    return (
      <footer className="ph3 ph4-ns pv6 bt b--black-10 black-70 pa3 pa4-ns tc">
        <p className="f6 db b ttu lh-solid">© {this.state.currentYear} Harvest Planner.</p>
        <div>
          <a href='mailto:info@harvestmore.org' title="Language" className="f6 dib pr2 mid-gray dim">info@harvestmore.org</a>
        </div>
      </footer>
    )
  }
}

export default Footer;
