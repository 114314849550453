import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class JoinButton extends Component {
  render() {
    return (
      <Link className="f6 ml1 link dim br2 ph3 pv2 mb2 dib black bg-white" to='/join' title='Sign In'>Sign Up</Link>
    )
  }
}
