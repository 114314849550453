import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '.25rem',
  marginBottom: '.25rem',
  cursor: 'move',
  float: 'left',
  fontSize: '13px'
};

const ItemTypes = {
  CROP: 'crop'
}

const cropSource = {
  beginDrag(props) {
    return {
      id: props.id,
      name: props.name,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    if (dropResult) {
      // props.plantIt();
      // window.alert( // eslint-disable-line no-alert
      //   `You dropped ${item.name} into ${dropResult.name}!`,
      // );
    }
  },
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Crop extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDropped: PropTypes.bool.isRequired,
    showCopyIcon: PropTypes.bool,
    id: PropTypes.number.isRequired
  };

  render() {
    const { name, isDropped, isDragging, connectDragSource, showCopyIcon } = this.props;
    const opacity = isDragging ? 0.4 : 1;
    const dropEffect = showCopyIcon ? 'copy' : 'move';

    return connectDragSource(
      <div className="cropbox mw6 truncate" style={{opacity, cursor: 'move' }}>
        {isDropped ?
          <s>{name}</s> :
          name
        }
      </div>,
      { dropEffect },
    );
  }
}

export default DragSource(ItemTypes.CROP, cropSource, collect)(Crop);
